import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Label } from "reactstrap";
import Logo from '../Media/LogoLogin.png';
import PopUp from "Components/PopUp";
import BuildDetailsComponent from "../BuildDetails/BuildDetailsComponent";
import { Roles } from "shared/constants";


const Footer = () => {
  const [loadingPopUpBool, setLoadingPopUpBool] = useState(false);

  return (
    <React.Fragment>
      <footer className="footer p-0">
        <Container fluid={true}>
          <Row>
            <Col md={4} className="pos-start d-flex align-items-center">
              <Label className="mb-0">ecoSAIL © v1.0.1 {new Date().getFullYear()}|</Label>{Roles.ES_ADMIN && (
                <Label Label className='mb-0 link_color_blue'
                  onClick={() => setLoadingPopUpBool(true)}>
                  Build
                </Label>)
              }
              <PopUp
                state={loadingPopUpBool}
                setState={setLoadingPopUpBool}
                body={
                  <BuildDetailsComponent />
                }
                title={'Build Information'}
                size={'sm'}
              />
            </Col>
            <Col md={4} className="text-sm-center d-none d-sm-block d-flex align-items-center">
              <img
                src={Logo}
                alt="Logo"
                height={40}
              />
            </Col>
            <Col md={4} className="d-flex align-items-center justify-content-end">
              Developed by
              <Link
                to="https://ecosailinfotech.com/"
                className="ms-1 text-decoration-underline"
                target="_blank"
              >
                ecoSAIL
              </Link>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment >
  );
};

export default Footer;

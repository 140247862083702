import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Input,
    Label,
    Button,
    Form,
    CardHeader,
    CardBody,
    Card,
    CardFooter,
} from "reactstrap";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import { errorToast, successToast } from "Components/Toasts";
import { getInputs } from "../EngineerReport.model";
import { TooltipMsg } from "../../../Components/ToolTipMessage";
import {
    FileStatus,
    FuelTypes,
    ReportingTypeConstant,
    VesselMachineryConstant,
    VesselTypeConstant,
} from "../../../shared/constants";
import * as Yup from "yup";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
    loadBatchConsumptionObject,
    loadFuelBatches,
    loadFuelConsumptionObject,
    loadFuelSettings,
    loadFuelTypeROB,
    loadLNGCargoPreviousROB,
    loadLNGCargoROBObject,
    loadMachineryFuelGroup,
    loadPreviousEOSPPort,
    loadPreviousFuelROB,
    loadProactiveAddDetailsObject,
    loadScrubingUnitObject,
    loadShoreSupplyObject,
    loadSteamDumpDetailsObject,
    loadVesselMachineries,
    loadVesselOtherSettingsOperation,
    vesselFuel,
} from "VesselMaster/vesselMaster.hooks";
import apiGlobal, { apiMedia, MediaBaseURL } from "global/api.global";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import ToolTip from "../../../Components/ToolTip";
import { queryClient } from "../../../react-query/queryClient";
import Select from 'react-select';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import { errResponse, formatBytes } from 'GenericForms/Helper';
import env from 'environment/env';
import FuelAdjustmentPopup from 'Components/FuelAdjustmentPopup';
import ErrorTooltip from 'Components/ErrorTooltip';
import Dropzone from 'react-dropzone';
import { useSelector } from "react-redux";
import { RootState } from "index";
interface FuelConsumptionComponentType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    toggleTab: any;
    activeTab: any;
    vessel: any;
}
const FuelConsumptionComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    toggleTab,
    activeTab,
    vessel,
}: FuelConsumptionComponentType) => {
    /** State for the form */
    const { Reports } = useSelector((state: RootState) => state.Reporting);
    let [fuelConsumptionSum] = useState<any>({});
    let [fuelBatchConsumptionSum] = useState<any>({});
    let [fuelBatchAdjustmentSum] = useState<any>({});
    const [forob] = useState<Array<any>>([]);
    const [batchROB] = useState<Array<any>>([]);
    let { Fuel_Consumption_inputs } = getInputs("fuel_consumption");
    const [measurementMethod, setMeasurementMethod] = useState<string>("Flow meter");
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [count, setCount] = useState(0);
    const [fuelBatchId, setFuelBatchId] = useState<any>(0);
    const [finalBatchConsumptionArray, setFinalBatchConsumptionArray] = useState<Array<any>>([]);
    const [totalLNGBunkerROB, setTotalLNGBunkerROB] = useState(0);
    const [EDNFiles, setEDNFiles] = useState<Array<any>>([]);
    const record = Reports.find((rec: any) => rec.id === ReportID);
    /** End State */

    /** Queries */
    /** Machinary fuel Group */
    const {
        data: machineryFuelGroup,
        isLoading: machineryFuelGroupLoading,
        isError: machineryFuelGroupError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroup.key, VesselID],
        async () => {
            return await loadMachineryFuelGroup(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Load Vessel Machinary */
    const {
        data: vesselMachineries,
        isLoading: vesselMachineriesLoading,
        isError: vesselMachineriesError,
    } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroup.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fetch fuel Batch Bunkering */
    const {
        data: fuelBatches,
        isLoading: fuelBatchesLoading,
        isError: fuelBatchesError,
    } = useQuery(
        [queryKeyes.vessel.Bunkaring.key, VesselID],
        async () => {
            return await loadFuelBatches(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fetch Vessel distinct fuel  */
    const {
        data: fuelTypes,
        isLoading: fuelTypesLoading,
        isError: fuelTypesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fetch Vessel fuel settings */
    const {
        data: fuelSettings,
        isLoading: fuelSettingsLoading,
        isError: fuelSettingsError,
    } = useQuery(
        [queryKeyes.vessel.FuelSettings.key, VesselID],
        async () => {
            return await loadFuelSettings(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Previous report's fuel ROB */
    const {
        data: PreviousFuelROB,
        isLoading: previousFuelROBLoading,
        isError: previousFuelROBError,
    } = useQuery(
        [queryKeyes.vessel.PreviousFuelROB.key, VesselID, ReportID],
        async () => {
            return await loadPreviousFuelROB(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Fuel Consumption Object used for edit */
    const {
        data: FuelConsumptionObject,
        isLoading: FuelConsumptionObjectLoading,
        isError: FuelConsumptionObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.FuelConsumptionObject.key, VesselID, ReportID],
        async () => {
            return await loadFuelConsumptionObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Batch Consumption Object used for edit */
    const {
        data: BatchConsumptionObject,
        isLoading: BatchConsumptionObjectLoading,
        isError: BatchConsumptionObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.BatchConsumptionObject.key, VesselID, ReportID],
        async () => {
            return await loadBatchConsumptionObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Fuel ROB Object used for edit */
    const {
        data: FuelROBObject,
        isLoading: FuelROBObjectLoading,
        isError: FuelROBObjectError,
    } = useQuery(
        [queryKeyes.vessel.FuelTypeROB.key, VesselID, VoyageID, ReportID],
        async () => {
            return await loadFuelTypeROB(VesselID, VoyageID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Shore supply Object used for edit */
    const {
        data: ShoreSupplyObject,
        isLoading: ShoreSupplyObjectLoading,
        isError: ShoreSupplyObjectError,
    } = useQuery(
        [queryKeyes.vessel.ShoreSupplyObject.key, VesselID, ReportID],
        async () => {
            return await loadShoreSupplyObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Scrubing unit Object used for edit */
    const {
        data: ScrubingUnitObject,
        isLoading: ScrubingUnitObjectLoading,
        isError: ScrubingUnitObjectError,
    } = useQuery(
        [queryKeyes.vessel.ScrubingUnitObject.key, VesselID, ReportID],
        async () => {
            return await loadScrubingUnitObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** LNG cargo's previous report's */
    const {
        data: LNGCargoPreviousROB,
        isLoading: LNGCargoPreviousROBLoading,
        isError: LNGCargoPreviousROBError,
    } = useQuery(
        [queryKeyes.vessel.LNGCargoPreviousROB.key, VesselID, ReportID],
        async () => {
            return await loadLNGCargoPreviousROB(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** LNG cargo ROB Object used for edit */
    const {
        data: LNGCargoROBObject,
        isLoading: LNGCargoROBObjectLoading,
        isError: LNGCargoROBObjectError,
    } = useQuery(
        [queryKeyes.vessel.LNGCargoROBObject.key, VesselID, ReportID],
        async () => {
            return await loadLNGCargoROBObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Vessel's other setting;s object used for edit */
    const {
        data: OtherSettings,
        isLoading: OtherSettingsLoading,
        isError: OtherSettingsError,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
        async () => {
            return await loadVesselOtherSettingsOperation(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Steam dump details Object used for edit */
    const {
        data: SteamDumpDetailsObject,
        isLoading: SteamDumpDetailsObjectLoading,
        isError: SteamDumpDetailsObjectError,
    } = useQuery(
        [queryKeyes.vessel.SteamDumpDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadSteamDumpDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Additional details object used for edit */
    const {
        data: AdditionalDetailsObject,
        isLoading: AdditionalDetailsObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.ProactiveAddDetailsObject.key, VesselID, ReportID],
        async () => {
            return await loadProactiveAddDetailsObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Previous End of Sea Passage's arrival port */
    const { data: PreviousEOSPPortObject, isLoading: PreviousEOSPPortObjectLoading, isError: PreviousEOSPPortObjectError, } = useQuery(
        [queryKeyes.vessel.PreviousEOSPPort.key, VesselID, ReportID],
        async () => {
            return await loadPreviousEOSPPort(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** End Queries */

    /** Assign initial values to fuel consumptiom formik object */
    const getFuelInitialValues = () => {
        let newFuelConsumption: any[] = [];
        if (FuelConsumptionObject && FuelConsumptionObject?.length > 0) {
            return FuelConsumptionObject;
        } else if (
            newFuelConsumption !== undefined &&
            machineryFuelGroup !== undefined &&
            fuelTypes !== undefined &&
            newFuelConsumption.length <= machineryFuelGroup.length * fuelTypes.length
        ) {
            machineryFuelGroup.flatMap((machine: any, index: number) => {
                fuelTypes.map((fuel: any) => {
                    newFuelConsumption.push({
                        vessel: VesselID,
                        vessel_machinery_fc_group: machine.id,
                        precedence_id: fuel.precedence_id,
                        vessel_fuel: fuel.fuel_type,
                        method_used_to_measure_fuel_oil_consumption: "Flow meter",
                        fuel_consumed: null,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return fuel;
                });
                return machine;
            });
            return newFuelConsumption;
        } else {
            return (newFuelConsumption = [
                {
                    fuel_consumed: 0,
                    vessel_machinery_fc_group: 0,
                    vessel_fuel: 0,
                    method_used_to_measure_fuel_oil_consumption: "Flow meter",
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                },
            ]);
        }
    };
    /** Assign initial values to batch consumptiom formik object */
    const getFuelBatchInitialValues = () => {
        let newFuelBatchConsumption: any[] = [];
        if (BatchConsumptionObject && BatchConsumptionObject.length > 0) {
            BatchConsumptionObject?.sort((a: any, b: any) => a.id - b.id).forEach(
                (batch: any) => {
                    batch.temp_adjustment_quantity = 0;
                    batch.temp_adjustment_reason = null as string;
                    newFuelBatchConsumption.push(batch);
                }
            );
            return newFuelBatchConsumption.sort((a: any, b: any) => a.id - b.id);
        } else if (
            fuelBatches !== undefined &&
            newFuelBatchConsumption.length <= fuelBatches.length
        ) {
            fuelBatches
                ?.sort((a: any, b: any) => a.id - b.id)
                ?.filter((item: any) => item.status === "inuse")
                .map((batch: any) => {
                    newFuelBatchConsumption.push({
                        fuel_consumption: null,
                        viscosity: 0,
                        rob: 0,
                        previous_rob: 0,
                        adjustment_quantity: 0,
                        temp_adjustment_quantity: 0,
                        precedence_id: batch?.precedence_id_type,
                        adjustment_reason: null as string,
                        temp_adjustment_reason: null as string,
                        fuel_name: batch?.fuel_type_name,
                        fuel_sub_type: batch?.fuel_sub_type,
                        fuel_batches: batch?.id,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return batch;
                });
            return newFuelBatchConsumption;
        } else {
            return (newFuelBatchConsumption = [
                {
                    fuel_consumption: 0,
                    viscosity: 0,
                    rob: 0,
                    previous_rob: 0,
                    fuel_name: 0,
                    fuel_batches: 0,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                },
            ]);
        }
    };
    /** Assign initial values to shore supply formik object */
    const getShoreSupplyInitialValues = () => {
        if (ShoreSupplyObject && ShoreSupplyObject.length > 0) {
            return ShoreSupplyObject[0];
        } else {
            if (PreviousEOSPPortObject) {
                return ({
                    is_edn_issue: false,
                    total_kw_used: null,
                    total_time: null,
                    co2_equivalent: null,
                    edn_file_upload: "",
                    is_shore_supply_available: false,
                    is_shore_supply_used: false,
                    port: PreviousEOSPPortObject?.arrival_port,
                    time_on_shore_supply: 0,
                    quantity_of_shore_supply: 0,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                });
            }
        }
    };
    /** Assign values to initial object of Srubing units */
    const getScrubingInitialValues = () => {
        if (ScrubingUnitObject && ScrubingUnitObject.length > 0) {
            return ScrubingUnitObject[0];
        } else {
            return {
                is_egcs_in_use: false,
                is_scr_in_use: false,
                is_egr_in_use: false,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
        }
    };
    /** Assign initial values to batch consumptiom formik object */
    const getLNGCargoROBInitialValues = () => {
        if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
            if (LNGCargoROBObject && LNGCargoROBObject.length > 0) {
                return LNGCargoROBObject[0];
            } else {
                return {
                    initial_rob: 0,
                    lng_initial_cargo_rob: null,
                    cargo_consumed: 0,
                    cargo_rob: LNGCargoPreviousROB?.cargo_rob ?? 0,
                    cargo_adjusted: 0,
                    adjustment_reason: "",
                    cargo_loaded_cargo_detail: null,
                    cargo_discharge_cargo_detail: null,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                };
            }
        }
    };
    /** Assign initial values to steam details formik object */
    const getInitialSteamDumpValues = () => {
        if (SteamDumpDetailsObject && SteamDumpDetailsObject[0]?.id > 0) {
            return SteamDumpDetailsObject[0];
        } else {
            return {
                steam_dump_valve_percentage: null as number,
                vessel: VesselID,
                voyage_information: VoyageID,
                vessel_reporting_information: ReportID,
            };
        }
    };

    /** useEffect */
    useEffect(() => {
        FuelConsumptionFormik.initialValues.fuelConsumption =
            getFuelInitialValues();
    }, [FuelConsumptionObject, machineryFuelGroup, fuelTypes]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.fuelConsumptionBatch =
            getFuelBatchInitialValues();
    }, [BatchConsumptionObject, fuelBatches]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.shoreSupply =
            getShoreSupplyInitialValues();
    }, [ShoreSupplyObject]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.lngCargoROB =
            getLNGCargoROBInitialValues();
    }, [LNGCargoPreviousROB, LNGCargoROBObject]);
    useEffect(() => {
        FuelConsumptionFormik.initialValues.steamDumpDetails =
            getInitialSteamDumpValues();
    }, [OtherSettings, SteamDumpDetailsObject]);
    useEffect(() => {
        const withLNGBunker: any[] = fuelBatches?.filter(
            (item: any) => item.precedence_id === FuelTypes.LNG_BUNKER
        );
        const withoutLNGBunker: any[] = fuelBatches?.filter(
            (item: any) => item.precedence_id !== FuelTypes.LNG_BUNKER
        );
        let finalArray: any[] = [];
        withoutLNGBunker?.forEach((without: any) => {
            finalArray.push(without);
        });
        if (withLNGBunker?.length > 0) {
            finalArray.push(withLNGBunker[0]);
        }
        setFinalBatchConsumptionArray(finalArray);
    }, [fuelBatches]);
    useEffect(() => {
        setTotalLNGBunkerROB(
            fuelBatches
                ?.filter(
                    (item: any) => item.precedence_id_type === FuelTypes.LNG_BUNKER
                )
                ?.reduce(
                    (sum: number, item: any) => sum + (item.quantity_remaining || 0),
                    0
                )
        );
    }, [fuelBatches]);
    /** useEffect end */

    /** Fuel Consumption Formik object */
    let FuelConsumptionFormik = {
        initialValues: {
            scrubingUnit: getScrubingInitialValues(),
            shoreSupply: getShoreSupplyInitialValues(),
            fuelConsumption: getFuelInitialValues(),
            fuelConsumptionBatch: getFuelBatchInitialValues(),
            lngCargoROB: getLNGCargoROBInitialValues(),
            steamDumpDetails: getInitialSteamDumpValues(),
        },
        validationSchema: Yup.object().shape({
            egcs_in_use: Yup.boolean(),
            scr_in_use: Yup.boolean(),
            egr_in_use: Yup.boolean(),
            shoreSupply: Yup.object({
                time_on_shore_supply: Yup.string()
                    .matches(/^(?:25(?:\.00?)?|(?:[0-1]?\d|2[0-4])(?:\.\d{1,2})?)$/, commonValidationMessages.max25)
                    .when('is_shore_supply_used', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    }),
                quantity_of_shore_supply: Yup.string()
                    .when('is_shore_supply_used', {
                        is: true,
                        then: (schema: any) => schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    })
                    .matches(/^\d{0,5}(?:\.\d{1,3})?$/, `${commonValidationMessages.before5after3}`),
            }),
            fuelConsumption: Yup.array(
                Yup.object({
                    fuel_consumed: Yup.number().required(
                        commonValidationMessages.required
                    ),
                    vessel_machinery_fc_group: Yup.number(),
                    vessel_fuel: Yup.number(),
                    vessel: Yup.number(),
                    voyage_information: Yup.number(),
                    vessel_reporting_information: Yup.number(),
                })
            ),
            fuelConsumptionBatch: Yup.array(
                Yup.object({
                    fuel_consumption: Yup.number().required(
                        commonValidationMessages.required
                    ),
                    viscosity: Yup.number().nullable(),
                    rob: Yup.number(),
                    previous_rob: Yup.number(),
                    fuel_name: Yup.number(),
                    fuel_batches: Yup.number(),
                    adjustment_quantity: Yup.string().matches(
                        /^[+-]?\d{0,7}(?:\.\d{1,2})?$/,
                        commonValidationMessages.before7after2
                    ),
                    adjustment_reason: Yup.string().when("adjustment_quantity", {
                        is: (value: number) => value > 0,
                        then: (schema: any) =>
                            schema.required(commonValidationMessages.required),
                        otherwise: (schema: any) => schema.nullable(),
                    }),
                    vessel: Yup.number(),
                    voyage_information: Yup.number(),
                    vessel_reporting_information: Yup.number(),
                }).test(function (value: any) {
                    if (value.fuel_consumption === null) {
                        value.fuel_consumption = 0;
                    }
                    const fuelConsumptionBatch: any = this.parent ? this.parent : [];
                    if (value.precedence_id !== FuelTypes.LNG_CARGO) {
                        const currentFuelType = value.precedence_id;
                        const currentBatch = value.fuel_batches;
                        const totalConsumptionForFuelType = fuelConsumptionBatch
                            .filter((item: any) => item.precedence_id === currentFuelType)
                            .reduce(
                                (sum: number, item: any) => sum + (item.fuel_consumption || 0),
                                0
                            );
                        let index = fuelConsumptionBatch.indexOf(value);
                        if (
                            currentFuelType !== FuelTypes.LNG_CARGO &&
                            currentFuelType !== FuelTypes.LNG_BUNKER
                        ) {
                            if (
                                fuelBatches.filter(
                                    (item: any) =>
                                        item.id === currentBatch &&
                                        item.precedence_id_type !== FuelTypes.LNG_BUNKER
                                )[0]?.previous_quantity_remaining < value.fuel_consumption
                            ) {
                                return new Yup.ValidationError(
                                    `Fuel batch consumption should be equal to or less than fuel batch ROB`,
                                    undefined,
                                    `finalBatchConsumptionArray.${index}`
                                );
                            }
                            if (
                                totalConsumptionForFuelType.toFixed(4) !==
                                fuelConsumptionSum[currentFuelType]?.toFixed(4)
                            ) {
                                return new Yup.ValidationError(
                                    `Total fuel batch consumption should be same as the total fuel consumption`,
                                    undefined,
                                    `finalBatchConsumptionArray.${index}`
                                );
                            }
                        }
                        if (
                            currentFuelType === FuelTypes.LNG_BUNKER &&
                            totalLNGBunkerROB < value.fuel_consumption
                        ) {
                            return new Yup.ValidationError(
                                `Fuel batch consumption should be equal to or less than fuel ROB`,
                                undefined,
                                `finalBatchConsumptionArray.${index}`
                            );
                        }
                        return true;
                    }
                })
            ),
            steamDumpDetails: Yup.object({
                steam_dump_valve_percentage: Yup.number()
                    .min(0, commonValidationMessages.min0)
                    .max(100, commonValidationMessages.maxVal100)
                    .nullable(),
            }),
        }),
    };

    /** Enable/disable fields based on machine-fuel applicability */
    const machineryEnabled = (machine: number, fuel: number) => {
        if (fuelSettings
            .filter((item: any) => item.vessel_machinery_fc_group === machine &&
                item.fuel_name === fuel &&
                item.vessel === VesselID)[0]?.selected === true) {
            return false;
        }
        return true;
    };

    /** Calculate the sum of fuel consumption */
    const loadFuelConsumptionSum = (fuelConsumption: any) => {
        if (fuelConsumption) {
            fuelConsumptionSum = fuelConsumption?.reduce((acc: any, curr: any) => {
                const { precedence_id, fuel_consumed } = curr;
                let fuel_consumed_float = parseFloat(fuel_consumed);
                if (isNaN(fuel_consumed_float)) {
                } else {
                    acc[precedence_id] = (acc[precedence_id] ?? 0) + fuel_consumed_float;
                }
                return acc;
            }, {});
            return fuelConsumptionSum;
        } else {
            return {};
        }
    };

    /** Calculate the sum of fuel batch consumption according to fuel type */
    const loadFuelBatchConsumptionSum = (fuelBatchConsumption: any) => {
        if (fuelBatchConsumption) {
            fuelBatchConsumptionSum = fuelBatchConsumption?.reduce(
                (acc: any, curr: any) => {
                    const { fuel_name, fuel_consumption } = curr;
                    let fuel_consumed_float = parseFloat(fuel_consumption);
                    if (isNaN(fuel_consumed_float)) {
                    } else {
                        acc[fuel_name] = (acc[fuel_name] ?? 0) + fuel_consumed_float;
                    }
                    return acc;
                },
                {}
            );
            return fuelBatchConsumptionSum;
        } else {
            return {};
        }
    };

    /** Calculate the sum of fuel batch adjustment according to fuel type */
    const loadFuelBatchAdjustmentSum = (fuelBatchAdjustment: any) => {
        if (fuelBatchAdjustment) {
            fuelBatchAdjustmentSum = fuelBatchAdjustment?.reduce(
                (acc: any, curr: any) => {
                    const { fuel_name, adjustment_quantity } = curr;
                    let fuel_adjusted_float = parseFloat(adjustment_quantity);
                    if (isNaN(fuel_adjusted_float)) {
                    } else {
                        acc[fuel_name] = (acc[fuel_name] ?? 0) + fuel_adjusted_float;
                    }
                    return acc;
                },
                {}
            );
            return fuelBatchAdjustmentSum;
        } else {
            return {};
        }
    };

    /** Assign values to viscosity, rob, previous_rob */
    const handleFuelBatchConsumptionChanges = (
        e: any,
        form: any,
        bunker: any,
        values: any,
        fuelbatch: any
    ) => {
        form.setFieldValue(
            `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                fuelbatch
            )}.viscosity`,
            bunker.viscosity
        );
        if (fuelbatch.precedence_id !== FuelTypes.LNG_BUNKER) {
            form.setFieldValue(
                `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                    fuelbatch
                )}.rob`,
                (bunker.previous_quantity_remaining - e.target.value).toFixed(2)
            );
            form.setFieldValue(
                `fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(
                    fuelbatch
                )}.previous_rob`,
                bunker.quantity_remaining
            );
        } else {
            let remainder: number = 0;
            values?.fuelConsumptionBatch
                .filter((item: any) => item.precedence_id === FuelTypes.LNG_BUNKER)
                .forEach((batch: any, index: number) => {
                    fuelBatches
                        .filter((item: any) => item.id === batch.fuel_batches)
                        .forEach((lngBunker: any) => {
                            batch.previous_rob = lngBunker.quantity_remaining;
                            if (!(e.target.value > totalLNGBunkerROB)) {
                                if (
                                    lngBunker.quantity_remaining === e.target.value ||
                                    lngBunker.quantity_remaining > e.target.value
                                ) {
                                    if (index === 0) {
                                        batch.fuel_consumption = e.target.value;
                                        batch.rob = lngBunker.quantity_remaining - e.target.value;
                                    } else {
                                        batch.fuel_consumption = 0;
                                        batch.rob = lngBunker.quantity_remaining;
                                    }
                                } else if (index === 0) {
                                    if (lngBunker.quantity_remaining < e.target.value) {
                                        batch.fuel_consumption = lngBunker.quantity_remaining;
                                        batch.rob = 0;
                                        remainder = e.target.value - lngBunker.quantity_remaining;
                                    }
                                } else if (index > 0) {
                                    if (lngBunker.quantity_remaining < remainder) {
                                        batch.fuel_consumption = lngBunker.quantity_remaining;
                                        batch.rob = 0;
                                        remainder -= lngBunker.quantity_remaining;
                                    } else {
                                        batch.fuel_consumption = remainder;
                                        batch.rob = lngBunker.quantity_remaining - remainder;
                                    }
                                }
                            }
                        });
                });
        }
        let batchObj = { ...bunker };
        if (!(BatchConsumptionObject && BatchConsumptionObject?.length > 0)) {
            batchObj.previous_quantity_remaining = batchObj.quantity_remaining;
        }
        batchObj.quantity_remaining = (
            batchObj.previous_quantity_remaining - e.target.value
        ).toFixed(2);
        if (batchROB.length > 0) {
            batchROB.map((rob: any, robIdx: number) => {
                if (rob.id === bunker.id) {
                    batchROB.splice(robIdx, 1);
                }
                return batchROB;
            });
        }
        batchROB.push(batchObj);
    };

    /** Fuel measurement method */
    const measurementMethodOptions: any[] = [
        { id: "1", label: "BDN" },
        { id: "2", label: "Flow meter" },
        { id: "3", label: "Bunkered fuel tank monitoring" },
        { id: "4", label: "None" },
    ];

    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    /** Show uploaded files */
    function handleAcceptedFiles(files: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setEDNFiles(files);
    }

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Fuel Consumption & ROB
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(previousFuelROBLoading ||
                FuelConsumptionObjectLoading ||
                BatchConsumptionObjectLoading ||
                FuelROBObjectLoading ||
                fuelSettingsLoading ||
                fuelTypesLoading ||
                machineryFuelGroupLoading ||
                vesselMachineriesLoading ||
                fuelBatchesLoading ||
                ShoreSupplyObjectLoading ||
                ScrubingUnitObjectLoading ||
                LNGCargoPreviousROBLoading ||
                LNGCargoROBObjectLoading ||
                OtherSettingsLoading ||
                SteamDumpDetailsObjectLoading ||
                AdditionalDetailsObjectLoading ||
                PreviousEOSPPortObjectLoading) && <Loading message='Loading required data!' />}

            {(previousFuelROBError ||
                fuelTypesError ||
                fuelBatchesError ||
                vesselMachineriesError ||
                machineryFuelGroupError ||
                fuelSettingsError ||
                FuelROBObjectError ||
                LNGCargoPreviousROBError ||
                OtherSettingsError ||
                PreviousEOSPPortObjectError) && (
                    <ErrorComponent message="Unable to load required data!" />
                )}

            {FuelConsumptionObjectError && getFuelInitialValues()}
            {BatchConsumptionObjectError && getFuelBatchInitialValues()}
            {ShoreSupplyObjectError && getShoreSupplyInitialValues()}
            {ScrubingUnitObjectError && getScrubingInitialValues()}
            {LNGCargoROBObjectError && getLNGCargoROBInitialValues()}
            {SteamDumpDetailsObjectError && getInitialSteamDumpValues()}

            {!(
                previousFuelROBLoading ||
                FuelConsumptionObjectLoading ||
                BatchConsumptionObjectLoading ||
                FuelROBObjectLoading ||
                fuelSettingsLoading ||
                fuelTypesLoading ||
                machineryFuelGroupLoading ||
                vesselMachineriesLoading ||
                fuelBatchesLoading ||
                ShoreSupplyObjectLoading ||
                ScrubingUnitObjectLoading ||
                LNGCargoPreviousROBLoading ||
                LNGCargoROBObjectLoading ||
                OtherSettingsLoading ||
                SteamDumpDetailsObjectLoading ||
                AdditionalDetailsObjectLoading ||
                PreviousEOSPPortObjectLoading) &&
                !(previousFuelROBError ||
                    fuelTypesError ||
                    fuelBatchesError ||
                    vesselMachineriesError ||
                    machineryFuelGroupError ||
                    fuelSettingsError ||
                    FuelROBObjectError ||
                    LNGCargoPreviousROBError ||
                    OtherSettingsError ||
                    PreviousEOSPPortObjectError) &&
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        /** Scrubing unit submit */
                        if (ScrubingUnitObject && ScrubingUnitObject.length > 0) {
                            apiGlobal.put(`/reporting_scrubing_units_detail/${values?.scrubingUnit?.id}/`, values?.scrubingUnit).then(res => {
                                if (res.status === 200) {
                                    successToast("Data saved successfully!");
                                    queryClient.invalidateQueries(queryKeyes.vessel.ScrubingUnitObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err?.response?.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        } else {
                            apiGlobal.post(`/reporting_scrubing_units_detail/`, values.scrubingUnit).then(res => {
                                if (res.status === 201) {
                                    successToast("Data saved successfully!");
                                    queryClient.invalidateQueries(queryKeyes.vessel.ScrubingUnitObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err?.response?.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        }

                        /** Shore supply submit */
                        if (values?.shoreSupply?.id) {
                            const formData = new FormData();
                            Object.keys(values.shoreSupply).forEach((item: any) => {
                                if (values.shoreSupply[item] === null || undefined) return;
                                formData.append(item, values.shoreSupply[item]);
                            });
                            formData.delete('edn_file_upload')
                            if (EDNFiles.length > 0) {
                                formData.append('edn_file_upload', EDNFiles[0])
                                formData.set('status', FileStatus.UPLOADED)
                            }
                            apiMedia.put(`/shore_supply/${values?.shoreSupply?.id}/`, formData).then(res => {
                                if (res.status === 200) {
                                    successToast("Data saved successfully!");
                                    values.shoreSupply = res.data
                                    queryClient.invalidateQueries(queryKeyes.vessel.ShoreSupplyObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err?.response?.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        } else {
                            if (values?.shoreSupply?.is_edn_issue === true) {
                                values.shoreSupply.edn_file_upload = EDNFiles[0];
                                values.shoreSupply.port = PreviousEOSPPortObject?.arrival_port;
                            }
                            const formData = new FormData();
                            Object.keys(values.shoreSupply).forEach((item: any) => {
                                if (values.shoreSupply[item] === null || undefined) return;
                                formData.append(item, values.shoreSupply[item]);
                            });
                            formData.delete('edn_file_upload')
                            if (EDNFiles.length > 0) {
                                formData.append('edn_file_upload', EDNFiles[0])
                                formData.set('status', FileStatus.UPLOADED)
                            } else {
                                formData.set('status', FileStatus.PENDING)
                            }
                            apiMedia.post(`/shore_supply/`, formData).then(res => {
                                if (res.status === 201) {
                                    successToast("Data saved successfully!");
                                    values.shoreSupply = res.data
                                    queryClient.invalidateQueries(queryKeyes.vessel.ShoreSupplyObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        }
                        /**Fuel consumption submit */
                        values.fuelConsumption.forEach((val: any) => {
                            val.method_used_to_measure_fuel_oil_consumption = measurementMethod;
                        })
                        if (FuelConsumptionObject && FuelConsumptionObject?.length > 0) {
                            values.fuelConsumption.map((val: any) => {
                                apiGlobal.put(`/FO_fuel_consumption/${val.id}/`, val)
                                    .then(res => {
                                        if (res.status === 200) {
                                            // successToast("Data saved successfully!");
                                            queryClient.invalidateQueries(queryKeyes.vessel.FuelConsumptionObject.key);
                                            if (env?.form_validation === true) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                                return '';
                            })
                        } else {
                            apiGlobal.post(`/FO_fuel_consumption/`, values.fuelConsumption)
                                .then(res => {
                                    if (res.status === 201) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.FuelConsumptionObject.key);
                                        if (env?.form_validation === true) {
                                            toggleTab(activeTab + 1);
                                        }
                                    }
                                })
                                .catch(err => {
                                    if (errResponse.includes(err?.response?.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                        }
                        /**Batch consumption submit */
                        values.fuelConsumptionBatch.map((batch: any) => {
                            fuelBatches.filter((item: any) => item.id === batch.fuel_batches && batch.fuel_consumption === 0.0)
                                .map((bunker: any) => {
                                    batch.rob = bunker.quantity_remaining;
                                    batch.previous_rob = bunker.quantity_remaining;
                                    return '';
                                })
                            return '';
                        })
                        if (BatchConsumptionObject && BatchConsumptionObject?.length > 0) {
                            values.fuelConsumptionBatch.map((val: any) => {
                                apiGlobal.put(`/fuel_consumption_batch/${val.id}/`, val)
                                    .then(res => {
                                        if (res.status === 200) {
                                            // successToast("Data saved successfully!");
                                            queryClient.invalidateQueries(queryKeyes.vessel.PreviousFuelROB.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.BatchConsumptionObject.key);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                                return val;
                            })
                        } else {
                            apiGlobal.post(`/fuel_consumption_batch/`, values.fuelConsumptionBatch)
                                .then(res => {
                                    if (res.status === 201) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.PreviousFuelROB.key);
                                        queryClient.invalidateQueries(queryKeyes.vessel.BatchConsumptionObject.key);
                                    }
                                })
                                .catch(err => {
                                    if (errResponse.includes(err?.response?.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                })
                        }
                        /**Fuel ROB submit */
                        if (FuelROBObject && FuelROBObject?.length > 0) {
                            FuelROBObject.map((val: any) => {
                                fuelTypes.filter((item: any) => item.fuel_type === val.vessel_fuel).map((type: any) => {
                                    if (type.fuel_type_name !== "LNG Cargo") {
                                        let prevFuelROB: any = FuelROBObject?.filter((item: any) =>
                                            item.vessel_fuel === type.fuel_type)[0].previous_rob;
                                        let fuelTotalConsumption = fuelConsumptionSum[type.fuel_type];
                                        let fuelBatchTotalAdjustment = fuelBatchAdjustmentSum[type.fuel_type]
                                        val.rob = (prevFuelROB - fuelTotalConsumption + fuelBatchTotalAdjustment).toFixed(2);
                                        val.total_fuel_consumed = fuelTotalConsumption.toFixed(2);
                                    }
                                    return '';
                                })
                                apiGlobal.put(`/fo_rob/${val.id}/`, val)
                                    .then(res => {
                                        if (res.status === 200) {
                                            successToast("Data saved successfully!");
                                            queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.PreviousFuelROB.key);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                                return fuelTypes;
                            })
                        } else {
                            fuelTypes.filter((item: any) => item.precedence_id !== FuelTypes.LNG_CARGO).map((type: any) => {
                                let prevFuelROB: any = PreviousFuelROB?.filter((item: any) => item.vessel_fuel === type.fuel_type)[0]?.rob ?? 0;
                                let fuelTotalConsumption = fuelConsumptionSum[type.fuel_type];
                                return forob.push({
                                    rob: (prevFuelROB - fuelTotalConsumption < 0) ? 0 :
                                        (prevFuelROB - fuelTotalConsumption).toFixed(2),
                                    bunkered_quantity: 0,
                                    debunkered_quantity: 0,
                                    total_fuel_consumed: fuelTotalConsumption.toFixed(2),
                                    previous_rob: prevFuelROB,
                                    vessel_fuel: type.fuel_type,
                                    vessel: VesselID,
                                    voyage_information: VoyageID,
                                    vessel_reporting_information: ReportID
                                })
                            });
                            apiGlobal.post(`/fo_rob/`, forob)
                                .then(res => {
                                    if (res.status === 201) {
                                        // successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.FuelTypeROB.key);
                                        queryClient.invalidateQueries(queryKeyes.vessel.PreviousFuelROB.key);
                                    }
                                })
                                .catch(err => {
                                    if (errResponse.includes(err?.response?.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                });
                        }
                        /**Bunkering update */
                        if (batchROB.length > 0) {
                            batchROB.map((rob: any) => {
                                if (rob.quantity_remaining === 0) {
                                    rob.status = 'consumed';
                                }
                                apiGlobal.put(`/bunkering/${rob.id}/`, rob)
                                    .then(res => {
                                        if (res.status === 200) {
                                            // successToast("Data saved successfully!");
                                            queryClient.invalidateQueries(queryKeyes.vessel.Bunkaring.key);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                                return batchROB;
                            })
                        }
                        /** LNG cargo ROB update */
                        if (vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) {
                            values.lngCargoROB.cargo_consumed = loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO];
                            if (Number.isNaN(parseFloat(LNGCargoPreviousROB?.cargo_rob)) === true) {
                                values.lngCargoROB.cargo_rob = 0;
                            } else {
                                values.lngCargoROB.cargo_rob = (parseFloat(LNGCargoPreviousROB?.cargo_rob ?? 0) -
                                    parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])
                                    + parseFloat(values.lngCargoROB.cargo_loaded_cargo_detail_value ?? 0) -
                                    parseFloat(values.lngCargoROB.cargo_discharge_cargo_detail_value ?? 0)
                                    + parseFloat(values.lngCargoROB.cargo_adjusted ?? 0)).toFixed(4)
                            }
                            if (LNGCargoROBObject && LNGCargoROBObject.length > 0) {
                                apiGlobal.put(`/lng_cargo_rob/${values?.lngCargoROB?.id}/`, values?.lngCargoROB)
                                    .then(res => {
                                        if (res.status === 200) {
                                            queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoPreviousROB.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                            } else {
                                apiGlobal.post(`/lng_cargo_rob/`, values?.lngCargoROB)
                                    .then(res => {
                                        if (res.status === 201) {
                                            successToast("Data saved successfully!");
                                            queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoPreviousROB.key);
                                            queryClient.invalidateQueries(queryKeyes.vessel.LNGCargoROBObject.key);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                            }
                        }
                        /** Steam Dump Details submit */
                        if (SteamDumpDetailsObject && SteamDumpDetailsObject[0]?.id > 0) {
                            apiGlobal.put(`/steam_dump_details/${values?.steamDumpDetails?.id}/`, values?.steamDumpDetails)
                                .then(res => {
                                    if (res.status === 200) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.SteamDumpDetailsObject.key);
                                    }
                                })
                                .catch(err => {
                                    if (errResponse.includes(err?.response?.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                });
                        } else {
                            apiGlobal.post(`/steam_dump_details/`, values?.steamDumpDetails)
                                .then(res => {
                                    if (res.status === 201) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.SteamDumpDetailsObject.key);
                                    }
                                })
                                .catch(err => {
                                    if (errResponse.includes(err?.response?.status)) {
                                        errorToast("Internal error occured, please contact the admin");
                                    }
                                });
                        }
                    }}
                    initialValues={FuelConsumptionFormik.initialValues}
                    validationSchema={FuelConsumptionFormik.validationSchema}
                >
                    {({ values, errors, handleSubmit, handleChange, setErrors, handleBlur, touched }: { values: any, errors: any, handleSubmit: any, handleChange: any, setErrors: any, handleBlur: any, touched: any }) => (
                        <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                            <CardBody className='engineer-card-body'>
                                {vesselMachineries?.filter((item: any) => (item.precedence_id === VesselMachineryConstant.EGCS ||
                                    item.precedence_id === VesselMachineryConstant.SCR ||
                                    item.precedence_id === VesselMachineryConstant.EGR
                                )).length > 0 &&
                                    <Row className='d-flex align-items-center p-2'>
                                        <Col sm={4}>
                                            {vesselMachineries?.filter((item: any) => item.precedence_id === VesselMachineryConstant.EGCS).length > 0 &&
                                                <div className="d-flex flex-wrap">
                                                    <Label className='mb-0' for='is_egcs_in_use'>
                                                        Is EGCS in use?<i className='bx bx-info-circle me-2' id='egcs_in_use_msg' />
                                                    </Label>
                                                    <ToolTip target='egcs_in_use_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'egcs_in_use_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'scrubingUnit.is_egcs_in_use'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id='is_egcs_in_use'
                                                                    onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)} />
                                                                <Label
                                                                    htmlFor={field.name}
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'
                                                                    className='mb-0'
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors?.scrubingUnit && errors?.scrubingUnit?.is_egcs_in_use && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`is_egcs_in_use`}
                                                            message={errors?.scrubingUnit?.is_egcs_in_use}
                                                            open={(errors?.scrubingUnit && errors?.scrubingUnit?.is_egcs_in_use) && !adjustmentPopup ? true : false}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Col>
                                        <Col sm={4}>
                                            {vesselMachineries?.filter((item: any) => item.precedence_id === VesselMachineryConstant.SCR).length > 0 &&
                                                <div className="d-flex flex-wrap">
                                                    <Label className='mb-0' for='is_scr_in_use'>Is SCR in use?
                                                        <i className='bx bx-info-circle me-2' id='scr_in_use_msg' />
                                                    </Label>
                                                    <ToolTip target='scr_in_use_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'scr_in_use_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'scrubingUnit.is_scr_in_use'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id='is_scr_in_use'
                                                                    onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)} />
                                                                <Label
                                                                    htmlFor={field.name}
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'
                                                                    className='mb-0'
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors?.scrubingUnit && errors?.scrubingUnit?.is_scr_in_use && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`is_scr_in_use`}
                                                            message={errors?.scrubingUnit?.is_scr_in_use}
                                                            open={(errors?.scrubingUnit && errors?.scrubingUnit?.is_scr_in_use) && !adjustmentPopup ? true : false}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Col>
                                        <Col sm={4}>
                                            {vesselMachineries?.filter((item: any) => item.precedence_id === VesselMachineryConstant.EGR).length > 0 &&
                                                <div className="d-flex flex-wrap">
                                                    <Label className='mb-0' for='is_egr_in_use'>
                                                        Is EGR in use?<i className='bx bx-info-circle me-2' id='egr_in_use_msg' />
                                                    </Label>
                                                    <ToolTip target='egr_in_use_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'egr_in_use_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={'scrubingUnit.is_egr_in_use'}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id={'is_egr_in_use'}
                                                                    onChange={(e: any) => form.setFieldValue(field.name, e.target.checked)} />
                                                                <Label
                                                                    htmlFor={field.name}
                                                                    data-on-label='Yes'
                                                                    data-off-label='No'
                                                                    className='mb-0'
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                    {errors?.scrubingUnit && errors?.scrubingUnit?.is_egr_in_use && env?.form_validation === true &&
                                                        <ErrorTooltip
                                                            target={`is_egr_in_use`}
                                                            message={errors?.scrubingUnit?.is_egr_in_use}
                                                            open={(errors?.scrubingUnit && errors?.scrubingUnit?.is_egr_in_use) && !adjustmentPopup ? true : false}
                                                        />
                                                    }
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                }
                                {OtherSettings[0]?.is_steam_dump_valve_in_use === true &&
                                    <Row>
                                        <Col className='p-0'>
                                            <Card className='mb-0 pr-0 rounded-0'>
                                                <CardHeader className='p-2'>
                                                    <div className="text-center">
                                                        <Row>
                                                            <Col>
                                                                <h4 className="page_title pos-start mb-0">Steam Dump Details</h4>
                                                                <p className="card-title-desc pos-start">All readings since last report</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </CardHeader>
                                                <CardBody sm={12} className='px-2 py-0 align-middle'>
                                                    <Row className='my-3'>
                                                        <Col className='d-flex'>
                                                            <div className='align-middle mr-1'>
                                                                <Label className='mb-0' htmlFor='valveDetails' for='valveDetails'>Steam dump valve</Label>
                                                            </div>
                                                            <div>
                                                                <div className='input-group'>
                                                                    <Field
                                                                        type="text"
                                                                        name="steamDumpDetails.steam_dump_valve_percentage"
                                                                        id="valveDetails"
                                                                        className="form-control text-right max-width-7"
                                                                    />
                                                                    <div className='input-group-text'>% open</div>
                                                                </div>
                                                                {errors?.steamDumpDetails && errors?.steamDumpDetails?.steam_dump_valve_percentage && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target='valveDetails'
                                                                        message={errors?.steamDumpDetails?.steam_dump_valve_percentage}
                                                                        open={errors?.steamDumpDetails && errors?.steamDumpDetails?.steam_dump_valve_percentage && !adjustmentPopup ? true : false}
                                                                    />
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                }
                                <Row className='px-0 m-0'>
                                    <Col sm={12} className='pr-0 px-0'>
                                        {record.reporting_type === ReportingTypeConstant.INPORT &&
                                            <Card className='rounded-0 mb-0 border-bottom-0 border-start-0'>
                                                <CardHeader className='p-0'>
                                                    <div className="text-center">
                                                        <Row>
                                                            <Col className='pr-0'>
                                                                <h4 className="page_title pos-start mb-0">Shore Supply</h4>
                                                                <p className="card-title-desc pos-start">All readings since last report</p>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </CardHeader>
                                                <CardBody className='px-0 py-0 shore_supply_margin'>
                                                    <Row>
                                                        <Col>
                                                            <div className="d-flex flex-wrap gap-2">
                                                                <Label for='is_shore_supply_used'>Is shore supply used?
                                                                    <i className='bx bx-info-circle ml-2p' id='is_shore_supply_used_msg' />
                                                                </Label>
                                                                <ToolTip target='is_shore_supply_used_msg'
                                                                    message={`${TooltipMsg.FuelConsumption
                                                                        .filter((item: any) => item.target === 'is_shore_supply_used_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <Field name={'shoreSupply.is_shore_supply_used'}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <div className="square-switch sqswitch">
                                                                            <Input
                                                                                type="checkbox"
                                                                                switch="none"
                                                                                name={field.name}
                                                                                id="is_shore_supply_used"
                                                                                onChange={(e: any) => {
                                                                                    form.setFieldValue(field.name, e.target.checked);
                                                                                    form.setFieldValue('shoreSupply.is_shore_supply_available', e.target.checked);
                                                                                }}
                                                                                defaultChecked={values?.shoreSupply.is_shore_supply_used}
                                                                            />
                                                                            <Label
                                                                                htmlFor="is_shore_supply_used"
                                                                                data-on-label='Yes'
                                                                                data-off-label='No'
                                                                            >
                                                                            </Label>
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        values && values?.shoreSupply.is_shore_supply_used === true &&
                                                        <>
                                                            <Row className='mb-3'>
                                                                <Col sm={2}>
                                                                    <Label className='mb-0' for='time_on_shore_supply'>Time on shore supply
                                                                        <i className='bx bx-info-circle ml-2p' id='time_on_shore_supply_msg' />
                                                                    </Label>
                                                                    <ToolTip target='time_on_shore_supply_msg'
                                                                        message={`${TooltipMsg.FuelConsumption
                                                                            .filter((item: any) => item.target === 'time_on_shore_supply_msg')
                                                                            .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className='input-group'>
                                                                        <Field
                                                                            type="text"
                                                                            name="shoreSupply.time_on_shore_supply"
                                                                            id='time_on_shore_supply'
                                                                            className="form-control text-right max-width-7"
                                                                        />
                                                                        <div className='input-group-text'>hr</div>
                                                                    </div>
                                                                    {errors?.shoreSupply && errors?.shoreSupply?.time_on_shore_supply && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`time_on_shore_supply`}
                                                                            message={errors?.shoreSupply?.time_on_shore_supply}
                                                                            open={(errors?.shoreSupply && errors?.shoreSupply?.time_on_shore_supply) && !adjustmentPopup ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <Label className='mb-0' for='quantity_of_shore_supply'>Quantity of shore supply used
                                                                        <i className='bx bx-info-circle ml-2p' id='quantity_of_shore_supply_msg' />
                                                                    </Label>
                                                                    <ToolTip target='quantity_of_shore_supply_msg'
                                                                        message={`${TooltipMsg.FuelConsumption
                                                                            .filter((item: any) => item.target === 'quantity_of_shore_supply_msg')
                                                                            .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <div className='input-group'>
                                                                        <Field
                                                                            type="text"
                                                                            name="shoreSupply.quantity_of_shore_supply"
                                                                            id='quantity_of_shore_supply'
                                                                            className="form-control text-right max-width-7 "
                                                                        />
                                                                        <div className='input-group-text'>kWh</div>
                                                                    </div>
                                                                    {errors?.shoreSupply && errors?.shoreSupply?.quantity_of_shore_supply && env?.form_validation === true &&
                                                                        <ErrorTooltip
                                                                            target={`quantity_of_shore_supply`}
                                                                            message={errors?.shoreSupply?.quantity_of_shore_supply}
                                                                            open={errors?.shoreSupply && errors?.shoreSupply?.quantity_of_shore_supply && !adjustmentPopup ? true : false}
                                                                        />
                                                                    }
                                                                </Col>
                                                                <Col sm={4}>
                                                                    <Label className='mb-0' for='port_name_msg'>Port Name
                                                                        <i className='ml-2p' id='port_name_msg' />
                                                                    </Label>
                                                                    <h4>
                                                                        {PreviousEOSPPortObject && PreviousEOSPPortObject?.arrival_port_name}
                                                                    </h4>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                    {env?.fuel_eu === true &&
                                                        <Row>
                                                            <Col sm={2}>
                                                                <div className="d-flex flex-wrap gap-2">
                                                                    <Label for='is_edn_issue' className="edn-label-width">Is EDN issued?
                                                                        <i className='bx bx-info-circle ml-2p' id='is_edn_issue_msg' />
                                                                    </Label>
                                                                    <ToolTip target='is_edn_issue_msg'
                                                                        message={`${TooltipMsg.FuelConsumption
                                                                            .filter((item: any) => item.target === 'is_edn_issue_msg')
                                                                            .map((tool: any) => { return tool.message })}`}
                                                                    />
                                                                    <Field name={'shoreSupply.is_edn_issue'}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <div className="square-switch sqswitch">
                                                                                <Input
                                                                                    type="checkbox"
                                                                                    switch="none"
                                                                                    name={field.name}
                                                                                    id="is_edn_issue"
                                                                                    onChange={(e: any) => {
                                                                                        form.setFieldValue(field.name, e.target.checked);
                                                                                        form.setFieldValue('shoreSupply.is_shore_supply_available', e.target.checked);
                                                                                    }}
                                                                                    defaultChecked={values?.shoreSupply.is_edn_issue}
                                                                                />
                                                                                <Label
                                                                                    htmlFor="is_edn_issue"
                                                                                    data-on-label='Yes'
                                                                                    data-off-label='No'
                                                                                >
                                                                                </Label>
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {values && values?.shoreSupply.is_edn_issue === true &&
                                                        <Row className='pe-2'>
                                                            <Col sm={2}>
                                                                <Label className='mb-0' for='total_time'>Total time
                                                                    <i className='bx bx-info-circle ml-2p' id='total_time_msg' />
                                                                </Label>
                                                                <ToolTip target='total_time_msg'
                                                                    message={`${TooltipMsg.FuelConsumption
                                                                        .filter((item: any) => item.target === 'total_time_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className='input-group'>
                                                                    <Field
                                                                        type="text"
                                                                        name="shoreSupply.total_time"
                                                                        id='total_time'
                                                                        className="form-control text-right max-width-7"
                                                                    />
                                                                    <div className='input-group-text'>hr</div>
                                                                </div>
                                                                {errors?.shoreSupply && errors?.shoreSupply?.total_time && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`total_time`}
                                                                        message={errors?.shoreSupply?.total_time}
                                                                        open={(errors?.shoreSupply && errors?.shoreSupply?.total_time) && !adjustmentPopup ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={2}>
                                                                <Label className='mb-0' for='total_kw_used'>Total quantity used
                                                                    <i className='bx bx-info-circle ml-2p' id='total_kw_used_msg' />
                                                                </Label>
                                                                <ToolTip target='total_kw_used_msg'
                                                                    message={`${TooltipMsg.FuelConsumption
                                                                        .filter((item: any) => item.target === 'total_kw_used_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className='input-group'>
                                                                    <Field
                                                                        type="text"
                                                                        name="shoreSupply.total_kw_used"
                                                                        id='total_kw_used'
                                                                        className="form-control text-right max-width-7 "
                                                                    />
                                                                    <div className='input-group-text'>kWh</div>
                                                                </div>
                                                                {errors?.shoreSupply && errors?.shoreSupply?.total_kw_used && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`total_kw_used`}
                                                                        message={errors?.shoreSupply?.total_kw_used}
                                                                        open={errors?.shoreSupply && errors?.shoreSupply?.total_kw_used && !adjustmentPopup ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                            <Col sm={2}>
                                                                <Label className='mb-0' for='co2_equivalent'>CO<sub>2</sub> equivalent
                                                                    <i className='bx bx-info-circle ml-2p' id='co2_equivalent_msg' />
                                                                </Label>
                                                                <ToolTip target='co2_equivalent_msg'
                                                                    message={`${TooltipMsg.FuelConsumption
                                                                        .filter((item: any) => item.target === 'co2_equivalent_msg')
                                                                        .map((tool: any) => { return tool.message })}`}
                                                                />
                                                                <div className='input-group'>
                                                                    <Field
                                                                        type="text"
                                                                        name="shoreSupply.co2_equivalent"
                                                                        id='co2_equivalent'
                                                                        className="form-control text-right max-width-7 "
                                                                    />
                                                                    <div className='input-group-text'>kWh</div>
                                                                </div>
                                                                {errors?.shoreSupply && errors?.shoreSupply?.co2_equivalent && env?.form_validation === true &&
                                                                    <ErrorTooltip
                                                                        target={`co2_equivalent`}
                                                                        message={errors?.shoreSupply?.co2_equivalent}
                                                                        open={errors?.shoreSupply && errors?.shoreSupply?.co2_equivalent && !adjustmentPopup ? true : false}
                                                                    />
                                                                }
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row className="pt-3">
                                                        <Col md={2}>
                                                            {values && values?.shoreSupply.is_edn_issue === true &&
                                                                <Dropzone
                                                                    onDrop={acceptedFiles => {
                                                                        handleAcceptedFiles(acceptedFiles);
                                                                    }}
                                                                >
                                                                    {({ getRootProps, getInputProps }) => (
                                                                        <div
                                                                            className="needsclick"
                                                                            {...getRootProps()}
                                                                        >
                                                                            <input
                                                                                {...getInputProps()}
                                                                                name={`shoreSupply.edn_file_upload`}
                                                                            />
                                                                            <Button type="button" className="btn waves-effect btn-label waves-light" color='primary'>
                                                                                <i className="mdi mdi-upload label-icon" />{values.shoreSupply.status === FileStatus.UPLOADED ?
                                                                                    'Replace File' :
                                                                                    'EDN File Upload'}</Button>
                                                                        </div>
                                                                    )}
                                                                </Dropzone>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            {values &&
                                                                values?.shoreSupply?.is_edn_issue === true &&
                                                                typeof values?.shoreSupply?.edn_file_upload === 'string' &&
                                                                values?.shoreSupply?.status === FileStatus.UPLOADED &&
                                                                <a className='' href={`${MediaBaseURL}/${values?.shoreSupply?.edn_file_upload?.substring(values?.shoreSupply?.edn_file_upload?.lastIndexOf('/') + 1)}`}
                                                                    target="_blank" rel="noopener noreferrer" title='View Uploaded File'
                                                                >
                                                                    <Button type="button" className="btn waves-effect btn-label waves-light mr-1"
                                                                        color='primary'>
                                                                        <i className="mdi mdi-file-document-outline label-icon" />
                                                                        View File
                                                                    </Button>
                                                                </a>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        }
                                    </Col>
                                </Row>
                                <Row className='px-0 m-0'>
                                    <Card className='px-0 rounded-0 mb-0 m-0 border-bottom-0 border-start-0 border-end-0'>
                                        <CardHeader className='p-2'>
                                            <div className="text-center">
                                                <Row>
                                                    <Col className='ps-0'>
                                                        <h4 className="page_title pos-start mb-0">Fuel Consumption</h4>
                                                        <p className="card-title-desc pos-start">All readings since last report</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardHeader>
                                        <CardBody className='p-0 mt-2'>
                                            <Row>
                                                <Col lg={4} className='ps-2'>
                                                    <Label className='mb-0' for='method_used_to_measure_fuel_oil_consumption'>
                                                        Method used to measure fuel consumption
                                                        <i className='bx bx-info-circle ml-2p' id='method_used_to_measure_fuel_oil_consumption_msg' />
                                                    </Label>
                                                    <ToolTip target='method_used_to_measure_fuel_oil_consumption_msg'
                                                        message={`${TooltipMsg.FuelConsumption
                                                            .filter((item: any) => item.target === 'method_used_to_measure_fuel_oil_consumption_msg')
                                                            .map((tool: any) => { return tool.message })}`}
                                                    />
                                                    <Field name={`fuelConsumption.${0}.method_used_to_measure_fuel_oil_consumption`}>
                                                        {({ field, form, }: FieldProps) => (
                                                            <Select
                                                                options={measurementMethodOptions}
                                                                getOptionLabel={(option: any) => option.label}
                                                                getOptionValue={(option: any) => option.id}
                                                                onChange={(e: any) => setMeasurementMethod(e.label)}
                                                                className='mb-3'
                                                                inputId='method_used_to_measure_fuel_oil_consumption'
                                                                defaultValue={{
                                                                    id: measurementMethodOptions
                                                                        .filter((item: any) => values.fuelConsumption[0].method_used_to_measure_fuel_oil_consumption === item.label)
                                                                        .map((method: any) => { return method.label }),
                                                                    label: values.fuelConsumption[0].method_used_to_measure_fuel_oil_consumption
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </Col>
                                            </Row>
                                            {(machineryFuelGroup && machineryFuelGroup?.length > 0) &&
                                                <div className="table-responsive">
                                                    <table className="table mb-0">
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th className='p-2 align-middle sr-no-width'>#</th>
                                                                {Fuel_Consumption_inputs.filter((table: any) => table.table_columns === true).sort((a: any, b: any) => a.table_columns_sequence - b.table_columns_sequence).map(({ name, ...props }) => {
                                                                    return (
                                                                        <th key={name} className={props.className + 'text-left p-2'}>{props.label}</th>
                                                                    )
                                                                })
                                                                }
                                                                {fuelTypes?.filter((item: any) => item.display === true)
                                                                    .sort((a: any, b: any) => a.precedence_id - b.precedence_id).map((fuelType: any, index: number) => {
                                                                        return (
                                                                            <th className="p-2 text-center" key={index}>{fuelType.fuel_type_name}</th>
                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {vesselMachineries.flatMap((machine: any, index: any) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                        <td className='p-2 align-middle'>{machine.vessel_machinery_fc_group_name}</td>
                                                                        <FieldArray name='fuelConsumption'>
                                                                            {() => (
                                                                                <>
                                                                                    {values.fuelConsumption
                                                                                        ?.filter((fuelConsumptionMachine: any) => fuelConsumptionMachine.vessel_machinery_fc_group === machine.id)
                                                                                        .map((fuel_consumptions: any, index: number) => (
                                                                                            <>
                                                                                                <td className="p-2 align-middle" key={index}>
                                                                                                    <div className="input-group justify-content-center">
                                                                                                        <Field name={`fuelConsumption.${values.fuelConsumption.indexOf(fuel_consumptions)}.fuel_consumed`}>
                                                                                                            {() => (
                                                                                                                <Input
                                                                                                                    name={`fuelConsumption.${values.fuelConsumption.indexOf(fuel_consumptions)}.fuel_consumed`}
                                                                                                                    type="text"
                                                                                                                    className="form-control max-width-7 text-right"
                                                                                                                    id={`fuel_consumed_${(values.fuelConsumption.indexOf(fuel_consumptions))}`}
                                                                                                                    onBlur={handleBlur}
                                                                                                                    onChange={(e: any) => {
                                                                                                                        handleChange(e);
                                                                                                                        setCount(count + 1);
                                                                                                                    }}
                                                                                                                    disabled={machineryEnabled(machine.id, fuel_consumptions.vessel_fuel)}
                                                                                                                    value={machineryEnabled(machine.id, fuel_consumptions.vessel_fuel) === true ? 0 :
                                                                                                                        values?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed}
                                                                                                                />
                                                                                                            )}
                                                                                                        </Field>
                                                                                                        <div className="input-group-text round_border">mt</div>
                                                                                                        {errors?.fuelConsumption && touched?.fuelConsumption && touched?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed &&
                                                                                                            errors?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed &&
                                                                                                            env?.form_validation === true &&
                                                                                                            <ErrorTooltip
                                                                                                                target={`fuel_consumed_${(values.fuelConsumption.indexOf(fuel_consumptions))}`}
                                                                                                                message={errors?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed}
                                                                                                                open={(errors?.fuelConsumption && errors?.fuelConsumption[(values.fuelConsumption.indexOf(fuel_consumptions))]?.fuel_consumed) &&
                                                                                                                    !adjustmentPopup ? true : false}
                                                                                                            />
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            </>
                                                                                        ))
                                                                                    }
                                                                                </>
                                                                            )}
                                                                        </FieldArray>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <th className="p-2" colSpan={2}></th>
                                                                {fuelTypes && fuelTypes.length > 0 && fuelTypes
                                                                    .sort((a: any, b: any) => a.precedence_id - b.precedence_id).map((fuelType: any) => {
                                                                        return (
                                                                            <th className="p-2 text-center" key={fuelType.id}>
                                                                                {fuelType.fuel_type_name} - {loadFuelConsumptionSum(values?.fuelConsumption)[fuelType.precedence_id]?.toFixed(4) || 0}
                                                                            </th>
                                                                        )
                                                                    }
                                                                    )}
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            }
                                        </CardBody>
                                    </Card>
                                </Row>
                                {/* <hr /> */}
                                {vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER &&
                                    <Row className='mt-3 mb-3 px-2'>
                                        <Col sm={4}>
                                            <span className='mb-0'>LNG Cargo ROB before consumption
                                                <i className='bx bx-info-circle ml-2p' id='initial_rob_msg' /> :
                                            </span>
                                            <ToolTip target='initial_rob_msg'
                                                message={`${TooltipMsg.FuelConsumption
                                                    .filter((item: any) => item.target === 'initial_rob_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <strong>&nbsp;{values?.lngCargoROB?.cargo_rob} mt</strong>
                                        </Col>
                                        <Col sm={5}>
                                            <span className='mb-0'>LNG Cargo ROB after consumption
                                                <i className='bx bx-info-circle ml-2p' id='cargo_rob_msg' /> :
                                            </span>
                                            <ToolTip target='cargo_rob_msg'
                                                message={`${TooltipMsg.FuelConsumption
                                                    .filter((item: any) => item.target === 'cargo_rob_msg')
                                                    .map((tool: any) => { return tool.message })}`}
                                            />
                                            <strong key={count}>&nbsp;
                                                {(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) === null ||
                                                    Number.isNaN(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]))) ? values?.lngCargoROB?.cargo_rob :
                                                    ((parseFloat(values?.lngCargoROB?.cargo_rob) - parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])))?.toFixed(4)
                                                } mt
                                            </strong>
                                        </Col>
                                        <Col sm={3}>
                                            <span className='mb-0'>LNG FOE :</span>
                                            <strong>&nbsp;
                                                {Number.isNaN((parseFloat(AdditionalDetailsObject[0]?.foe_factor) * ((parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) === null ||
                                                    Number.isNaN(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]))) ? values?.lngCargoROB?.cargo_rob :
                                                    ((parseFloat(values?.lngCargoROB?.cargo_rob) - parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])))))) === true ? AdditionalDetailsObject[0]?.foe_factor ?? 0 :
                                                    (parseFloat(AdditionalDetailsObject[0]?.foe_factor) * ((parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]) === null ||
                                                        Number.isNaN(parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO]))) ? values?.lngCargoROB?.cargo_rob :
                                                        ((parseFloat(values?.lngCargoROB?.cargo_rob) - parseFloat(loadFuelConsumptionSum(values?.fuelConsumption)[FuelTypes.LNG_CARGO])))))?.toFixed(4)} mt</strong>
                                        </Col>
                                    </Row>
                                }
                                <Row className='px-0 m-0'>
                                    <Card className='px-0 rounded-0 mb-0 border-start-0 border-end-0 border-bottom-0'>
                                        <CardHeader className='p-2'>
                                            <div className="text-center">
                                                <Row>
                                                    <Col className='ps-0'>
                                                        <h4 className="page_title pos-start mb-0">Fuel Consumption - Batch</h4>
                                                        <p className="card-title-desc pos-start">All readings since last report</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardHeader>
                                        <CardBody className='px-0 py-0 mt-2'>
                                            <Row>
                                                {fuelTypes && fuelTypes?.length > 0 &&
                                                    <div className="table-responsive pb-2">
                                                        <table className="table mb-0 p-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th className='p-2 align-middle sr-no-width'>#</th>
                                                                    <th className='p-2 align-middle'>Fuel</th>
                                                                    <th className='p-2 align-middle'>Details</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    fuelTypes?.filter((item: any) => item.precedence_id !== FuelTypes.LNG_CARGO).map((fuelType: any, index: number) => {
                                                                        return (
                                                                            <>
                                                                                <tr key={`fueltypec-${fuelType.fuel_type}`} data-id={`fueltype-${fuelType.fuel_type}`}>
                                                                                    <td className='p-2 text-center'>{index + 1}</td>
                                                                                    <td className='p-2'>{fuelType.fuel_type_name}<br />
                                                                                        <small>
                                                                                            Total consp: <b>{loadFuelConsumptionSum(values?.fuelConsumption)[fuelType.fuel_type]?.toFixed(4)} mt</b>
                                                                                        </small><br />
                                                                                        <small>ROB before consp: <b>
                                                                                            {(FuelROBObject?.length > 0 && FuelROBObject[0].id > 0) ?
                                                                                                FuelROBObject.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                                                    return parseFloat(rob.previous_rob).toFixed(4);
                                                                                                }) :
                                                                                                (PreviousFuelROB && PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).length === 0) ?
                                                                                                    0.0000 : PreviousFuelROB &&
                                                                                                    PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                                                        return parseFloat(rob.rob).toFixed(4);
                                                                                                    })}
                                                                                        </b>
                                                                                        </small><br />
                                                                                        <small>ROB after consp: <b>
                                                                                            {(FuelROBObject?.length > 0 && FuelROBObject[0].id > 0) ?
                                                                                                (FuelROBObject.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                                                    return parseFloat(rob.previous_rob).toFixed(4);
                                                                                                }) - parseFloat(loadFuelBatchConsumptionSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0) +
                                                                                                    parseFloat(loadFuelBatchAdjustmentSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0)).toFixed(4) :
                                                                                                (PreviousFuelROB && PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).length === 0) ?
                                                                                                    0.0000 : PreviousFuelROB &&
                                                                                                    (parseFloat(PreviousFuelROB?.filter((item: any) => item.vessel_fuel === fuelType.fuel_type).map((rob: any) => {
                                                                                                        return rob.rob;
                                                                                                    })) - parseFloat(loadFuelBatchConsumptionSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0) +
                                                                                                        parseFloat(loadFuelBatchAdjustmentSum(values?.fuelConsumptionBatch)[fuelType.fuel_type]?.toFixed(4) || 0))
                                                                                                        .toFixed(4)}
                                                                                        </b>
                                                                                        </small>
                                                                                        <div>
                                                                                            {typeof errors.fuelConsumptionBatch === 'string' ? (
                                                                                                <Label className='error'>{errors.fuelConsumptionBatch}</Label>
                                                                                            ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='p-0 m-0'>
                                                                                        <table className='table-bordered width-100 p-0 m-0'>
                                                                                            <thead className="table-light">
                                                                                                <tr>
                                                                                                    <th style={{ width: '56%' }} className='p-2 align-middle'>Fuel batches</th>
                                                                                                    <th style={{ width: '14%' }} className='p-2 align-middle text-center'>ROB</th>
                                                                                                    <th style={{ width: '15%' }} className='p-2 align-middle text-center asteric'>Consumption</th>
                                                                                                    <th style={{ width: '25%' }} className='p-2 align-middle text-center'>Adjustment</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {finalBatchConsumptionArray?.filter((item: any) => item.fuel_type_name === fuelType.fuel_type && item.quantity_remaining > 0)
                                                                                                    .map((batch: any, idx: number) => (
                                                                                                        <tr key={idx}>
                                                                                                            <td className='p-2 align-middle'>
                                                                                                                {batch.precedence_id_type === FuelTypes.LNG_BUNKER ? 'LNG' : batch.bunkering_supply_name}</td>
                                                                                                            <td className='p-2 text-center align-middle'>
                                                                                                                <Label
                                                                                                                    className={
                                                                                                                        (((totalLNGBunkerROB - parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                            fuel.fuel_batches === batch.id)[0]?.fuel_consumption) +
                                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)) < 0) &&
                                                                                                                            batch.precedence_id_type === FuelTypes.LNG_BUNKER) ?
                                                                                                                            'label-red mb-0' : (((parseFloat(batch?.previous_quantity_remaining) -
                                                                                                                                parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                    fuel.fuel_batches === batch.id)[0]?.fuel_consumption) +
                                                                                                                                parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                    fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)) < 0) &&
                                                                                                                                batch.precedence_id_type !== FuelTypes.LNG_BUNKER) ? 'label-red mb-0' : 'mb-0'}
                                                                                                                >{batch.precedence_id_type === FuelTypes.LNG_BUNKER &&
                                                                                                                    Number.isNaN((totalLNGBunkerROB -
                                                                                                                        (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                            fuel.fuel_batches === batch.id)[0]?.fuel_consumption) ?? 0) +
                                                                                                                        (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                            fuel.fuel_batches === batch.id)[0]?.adjustment_quantity) ?? 0)
                                                                                                                    )) ? totalLNGBunkerROB :
                                                                                                                    batch.precedence_id_type === FuelTypes.LNG_BUNKER &&
                                                                                                                        !Number.isNaN((totalLNGBunkerROB -
                                                                                                                            (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.fuel_consumption) ?? 0) +
                                                                                                                            (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.adjustment_quantity) ?? 0)
                                                                                                                        )) ?
                                                                                                                        (totalLNGBunkerROB -
                                                                                                                            (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.fuel_consumption) ?? 0) +
                                                                                                                            (parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.adjustment_quantity) ?? 0)
                                                                                                                        ).toFixed(4) :
                                                                                                                        Number.isNaN((parseFloat(batch?.quantity_remaining ?? 0)) -
                                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.fuel_consumption ?? 0) +
                                                                                                                            parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)
                                                                                                                        ) ? batch?.quantity_remaining :
                                                                                                                            ((parseFloat(batch?.quantity_remaining ?? 0)) -
                                                                                                                                parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                    fuel.fuel_batches === batch.id)[0]?.fuel_consumption ?? 0) +
                                                                                                                                parseFloat(values?.fuelConsumptionBatch?.filter((fuel: any) =>
                                                                                                                                    fuel.fuel_batches === batch.id)[0]?.adjustment_quantity ?? 0)
                                                                                                                            ).toFixed(4)
                                                                                                                    } mt</Label>
                                                                                                            </td>
                                                                                                            <FieldArray name='fuelConsumptionBatch'>
                                                                                                                {() => (
                                                                                                                    <>
                                                                                                                        {values && values?.fuelConsumptionBatch?.filter((fuel: any) => fuel.fuel_batches === batch.id)
                                                                                                                            .map((fuelbatch: any, index: number) => {
                                                                                                                                return (
                                                                                                                                    <>
                                                                                                                                        <td className='p-2 align-middle text-center'>
                                                                                                                                            <div className='d-inline-block'>
                                                                                                                                                <Field name={`fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(fuelbatch)}.fuel_consumption`}>
                                                                                                                                                    {({ field, form }: FieldProps) => (
                                                                                                                                                        <div className="input-group">
                                                                                                                                                            <Input
                                                                                                                                                                type="text"
                                                                                                                                                                className="form-control text-right max-width-7"
                                                                                                                                                                id={`fuel_consumption_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                                                                name={field.name}
                                                                                                                                                                onChange={(e: any) => {
                                                                                                                                                                    handleChange(e);
                                                                                                                                                                    handleFuelBatchConsumptionChanges(e, form, batch, values, fuelbatch);
                                                                                                                                                                }}
                                                                                                                                                                onBlur={(e: any) => {
                                                                                                                                                                    handleBlur(e);
                                                                                                                                                                    handleChange(e);
                                                                                                                                                                    handleFuelBatchConsumptionChanges(e, form, batch, values, fuelbatch);
                                                                                                                                                                }}
                                                                                                                                                                defaultValue={BatchConsumptionObject !== undefined && BatchConsumptionObject.length > 0 ?
                                                                                                                                                                    BatchConsumptionObject?.filter((item: any) => item.id === fuelbatch.id)[0]?.fuel_consumption : null
                                                                                                                                                                }
                                                                                                                                                            />
                                                                                                                                                            <div className="input-group-text">mt</div>
                                                                                                                                                        </div>
                                                                                                                                                    )}
                                                                                                                                                </Field>
                                                                                                                                            </div>
                                                                                                                                            {errors?.fuelConsumptionBatch && touched?.fuelConsumptionBatch && touched?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption &&
                                                                                                                                                errors?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption && env?.form_validation === true &&
                                                                                                                                                <ErrorTooltip
                                                                                                                                                    target={`fuel_consumption_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                                                    message={errors?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption}
                                                                                                                                                    open={(errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[(values?.fuelConsumptionBatch.indexOf(fuelbatch))]?.fuel_consumption) && !adjustmentPopup ? true : false}
                                                                                                                                                />
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td className='p-3 align-middle text-center'>
                                                                                                                                            <div className='d-inline-block'>
                                                                                                                                                <Field name={`fuelConsumptionBatch.${values?.fuelConsumptionBatch.indexOf(fuelbatch)}.adjustment_quantity`}>
                                                                                                                                                    {({ field, form }: FieldProps) => (
                                                                                                                                                        <div className="input-group">
                                                                                                                                                            <Input
                                                                                                                                                                type='text'
                                                                                                                                                                name={field.name}
                                                                                                                                                                id={`adjustment_quantity_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                                                                onClick={() => {
                                                                                                                                                                    setAdjustmentPopup(true);
                                                                                                                                                                    setFuelBatchId(values?.fuelConsumptionBatch.indexOf(fuelbatch));
                                                                                                                                                                }}
                                                                                                                                                                onKeyUp={() => {
                                                                                                                                                                    if (fuelBatchId !== values?.fuelConsumptionBatch.indexOf(fuelbatch)) {
                                                                                                                                                                        setFuelBatchId(values?.fuelConsumptionBatch.indexOf(fuelbatch));
                                                                                                                                                                    }
                                                                                                                                                                    setAdjustmentPopup(true);
                                                                                                                                                                }}
                                                                                                                                                                className='form-control text-right max-width-7'
                                                                                                                                                                value={values?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]?.adjustment_quantity}
                                                                                                                                                            />
                                                                                                                                                            <div className="input-group-text">mt</div>
                                                                                                                                                            {errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]?.adjustment_quantity && env?.form_validation === true &&
                                                                                                                                                                <ErrorTooltip
                                                                                                                                                                    target={`adjustment_quantity_${values?.fuelConsumptionBatch.indexOf(fuelbatch)}`}
                                                                                                                                                                    message={errors?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]?.adjustment_quantity}
                                                                                                                                                                    open={(errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[values?.fuelConsumptionBatch.indexOf(fuelbatch)]?.adjustment_quantity) && !adjustmentPopup ? true : false}
                                                                                                                                                                />
                                                                                                                                                            }
                                                                                                                                                        </div>
                                                                                                                                                    )}
                                                                                                                                                </Field>
                                                                                                                                            </div>
                                                                                                                                        </td>
                                                                                                                                    </>
                                                                                                                                )
                                                                                                                            })}
                                                                                                                    </>
                                                                                                                )}
                                                                                                            </FieldArray>
                                                                                                        </tr>
                                                                                                    ))}
                                                                                            </tbody>
                                                                                            <FuelAdjustmentPopup
                                                                                                state={adjustmentPopup}
                                                                                                setState={setAdjustmentPopup}
                                                                                                tog_backdrop={tog_backdrop}
                                                                                                target={`fuelConsumptionBatch_${fuelBatchId}`}
                                                                                                fuelBatch={fuelBatchId}
                                                                                            />
                                                                                        </table>
                                                                                    </td>
                                                                                </tr>
                                                                                {
                                                                                    errors?.finalBatchConsumptionArray &&
                                                                                    errors?.finalBatchConsumptionArray[values?.fuelConsumptionBatch.findIndex((item: any) => item.fuel_name === fuelType.fuel_type)] && (
                                                                                        <tr>
                                                                                            <td colSpan={5}>
                                                                                                <div className='label-red pos-end2'>{errors?.finalBatchConsumptionArray[values?.fuelConsumptionBatch.findIndex((item: any) => item.fuel_name === fuelType.fuel_type)]}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                }
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </CardBody>
                            <CardFooter className='p-2 py-3'>
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button type="submit" color="primary" className="btn_size_cstm pos-end" onClick={(e) => {
                                            values?.fuelConsumption?.forEach((val: any) => {
                                                if (machineryEnabled(val.vessel_machinery_fc_group, val.vessel_fuel) === true) {
                                                    val.fuel_consumed = 0;
                                                }
                                            })
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}>Next <i className="bx bx-chevron-right ms-1" /></Button>
                                        <Button type="button" color="primary" className="btn_size_cstm" onClick={(e) => {
                                            setErrors({});
                                            toggleTab(activeTab - 1);
                                        }}><i className="bx bx-chevron-left me-1" /> Previous</Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[values, errors, FuelConsumptionFormik.initialValues]} />
                        </Form >
                    )
                    }
                </Formik >
            }
        </Card >
    )
}

export default FuelConsumptionComponent;

import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { queryKeyes } from 'shared/queryKeys';
import { vesselFuel } from 'VesselMaster/vesselMaster.hooks';
import Loading from './Loading';
import ErrorComponent from './ErrorComponent';

interface EUMRVFuelEUTableType {
    eumrvData: any,
    operation: string
}

const EUMRVFuelEUTable = ({
    eumrvData,
    operation
}: EUMRVFuelEUTableType) => {
    /** State variables start */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [consumptionEmmission, setConsumptionEmmission] = useState<Array<any>>([]);
    /** State varibles end */

    /** useQueries */
    /** Fetch Vessel distinct fuel  */
    const {
        data: fuelTypes,
        isLoading: fuelTypesLoading,
        isError: fuelTypesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    useEffect(() => {
        let array: any[] = []
        if (operation === 'FuelEU') {
            array.push('Fuel Consumption');
        } else {
            array.push('Fuel Consumption', 'CO2', 'CH4', 'N2O');
        }
        setConsumptionEmmission(array);
    }, [operation])

    return (
        <React.Fragment>
            {fuelTypesLoading && <Loading message='Loading required data!' />}
            {fuelTypesError && <ErrorComponent message='Error loading required data!' />}
            {(!fuelTypesLoading && !fuelTypesError) &&
                <div className="table-responsive vertical-table-scroll">
                    <table className="table m-0">
                        <thead className="table-light">
                            <tr>
                                <th className="text-center p-2">Sr. No.</th>
                                <th className='text-center p-2'>Start date (dd/mm/yyyy)</th>
                                <th className='text-center p-2'>End date (dd/mm/yyyy)</th>
                                <th className='text-center p-2'>Departure port</th>
                                <th className='text-center p-2'>Arrival port</th>
                                <th className='text-center p-2' colSpan={fuelTypes?.length}>Fuel consumption</th>
                                {operation === 'EUMRV' &&
                                    <React.Fragment>
                                        <th className='text-center p-2' colSpan={fuelTypes?.length}>CO<sub>2</sub> emmission</th>
                                        <th className='text-center p-2' colSpan={fuelTypes?.length}>CH<sub>4</sub> emmission</th>
                                        <th className='text-center p-2' colSpan={fuelTypes?.length}>N<sub>2</sub>O emmission</th>
                                    </React.Fragment>
                                }
                                <th className='text-center p-2'>Applicability</th>
                                {operation === 'EUMRV' &&
                                    <th className='text-center p-2'>Emmission applicable to EU</th>
                                }
                                {operation === 'EUMRV' ?
                                    <th className='text-center p-2'>EUA</th> :
                                    <th className='text-center p-2'>GHGIE</th>
                                }
                            </tr>
                            <tr>
                                <th colSpan={5}></th>
                                {consumptionEmmission?.map((item: any) => (
                                    <React.Fragment key={item}>
                                        {fuelTypes?.map((fuel: any) => {
                                            return (
                                                <th key={fuel.id} className='text-center p-2'>
                                                    {fuel?.fuel_type_name}
                                                </th>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}
                                <th colSpan={3}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {eumrvData?.eumrvData && eumrvData.eumrvData?.length > 0 ?
                                (eumrvData?.eumrvData?.map((item: any, index: number) => {
                                    return (
                                        <tr>
                                            <td className='text-center p-2'>{index + 1}</td>
                                            <td className='text-center p-2'>{item?.start_date?.toString().substring(0, 10)} {item?.start_date?.toString().substring(11, 16)} 12:00</td>
                                            <td className='text-center p-2'>{item?.end_date?.toString().substring(0, 10)} {item?.end_date?.toString().substring(11, 16)}</td>
                                            <td className='text-center p-2'>{item?.departure_port}</td>
                                            <td className='text-center p-2'>{item?.arrival_port}</td>
                                            <td className='text-center p-2'>{item?.fo_fuel_consumption?.toFixed(2)}</td>
                                            <td className='text-center p-2'>{item?.do_fuel_consumption?.toFixed(2)}</td>
                                            {operation === 'EUMRV' &&
                                                <React.Fragment>
                                                    <td className='text-center p-2'>{item?.co2_emission_fo?.toFixed(2)}</td>
                                                    <td className='text-center p-2'>{item?.co2_emission_do?.toFixed(2)}</td>
                                                    <td className='text-center p-2'>{item?.ch4_emission_fo?.toFixed(4)}</td>
                                                    <td className='text-center p-2'>{item?.ch4_emission_do?.toFixed(4)}</td>
                                                    <td className='text-center p-2'>{item?.n2o_emission_fo?.toFixed(4)}</td>
                                                    <td className='text-center p-2'>{item?.n2o_emission_do?.toFixed(4)}</td>
                                                </React.Fragment>
                                            }
                                            <td className='text-center p-2'>{item?.applicability} - {item?.emission_applicable_to_eu + '%'}</td>
                                            {operation === 'EUMRV' &&
                                                <td className='text-center p-2'>{item?.emission_applicable_to_eu === 0 ? 0 : ((item?.co2_emission_fo + item?.co2_emission_do) * item?.emission_applicable_to_eu / 100)?.toFixed(2)}</td>
                                            }
                                            {operation === 'EUMRV' ?
                                                <td className='text-center p-2'>{(((item?.co2_emission_fo + item?.co2_emission_do) * item?.emission_applicable_to_eu / 100) * 0.4)?.toFixed(2)}</td> :
                                                <td className='text-center p-2'>{item?.ghgie}</td>
                                            }
                                        </tr>
                                    )
                                })) :
                                <tr>
                                    <td colSpan={operation === 'EUMRV' ? 12 : 9} className='text-left p-2'>No data avaliable</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </React.Fragment>
    )
}

export default EUMRVFuelEUTable
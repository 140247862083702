import apiGlobal from '../../../global/api.global';
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadCargoDischargingWithkWh } from 'VesselMaster/vesselMaster.hooks';
import ErrorComponent from 'Components/ErrorComponent';


const CargoDischarging = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /** Queries */
    /** Load Cargo Discharging wuth KWH machinaries */
    const { data: CargoDischarging, isLoading: CargoDischargingLoading, isError: CargoDischargingError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoDischargingWithkWhMachineries.key, VesselID],
            async () => { return await loadCargoDischargingWithkWh(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /** Assign initial values to cargo Discharging formik object */
    const getCargoDischargingInitailValues = () => {
        if (CargoDischarging?.length > 0) {
            return CargoDischarging
        }
    }

    /** cargo Discharging Formik Object */
    const CargoDischargingFormik = {
        initialValues: {
            cargoDischarging: getCargoDischargingInitailValues()
        },
        validationSchema: {
        }
    }

    return (
        <>
            <Card className='border-0'>
                {CargoDischargingLoading && <Loading message="Loading required data!" />}
                {CargoDischargingError && <ErrorComponent message="Error loading required data!" />}
                {!(CargoDischargingLoading) && !(CargoDischargingError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false)
                            values?.cargoDischarging?.foreach((val: any) => {
                                apiGlobal.put(`/special_operation_energy_setting/${val.id}/`, val)
                                    .then(res => {
                                        if (res.status === 200) {
                                            successToast("Data saved successfully!");
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                            })
                        }}
                        initialValues={CargoDischargingFormik.initialValues}
                    >
                        {props => (
                            <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0'>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                            <th className='p-2 align-middle'>Machinery name</th>
                                                            <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='cargoDischarging'>
                                                            {() => (
                                                                <>
                                                                    {CargoDischargingFormik?.initialValues &&
                                                                        CargoDischargingFormik?.initialValues?.cargoDischarging?.map((cargoDischarging: any, index: number) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{cargoDischarging.vessel_machinery_ec_group_name}</td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <Field name={`cargoDischarging.${index}.cargo_discharging_kwh_meter`}>
                                                                                            {({ field }: FieldProps) => (
                                                                                                <Input
                                                                                                    type='checkbox'
                                                                                                    id={`cargoDischarging.${index}.cargo_discharging_kwh_meter`}
                                                                                                    name={field.name}
                                                                                                    {...field}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                            }
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className='p-2 py-3 mb-3'>
                                    <Button type="submit" color='primary' className='justify_right'>Save</Button>
                                </CardFooter>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props?.values, props?.errors, CargoDischargingFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card>
        </>
    )
}
export default CargoDischarging;
import React from "react";
import { toggleLeftmenu } from "../Store/actions";
import '../global/GlobalCSS.css'
import ProfileMenu from "./TopbarDropdown/ProfileMenu";
import { useSelector, useDispatch } from "react-redux";
import env from "environment/env";
import { Col, Container, Row } from "reactstrap";

const Header = (props: any) => {
  const dispatch = useDispatch();
  const { leftMenu } = useSelector((state: any) => ({
    leftMenu: state.Layout.leftMenu,
    layoutMode: state.Layout.layoutMode,
    showRightSidebar: state.Layout.showRightSide
  }));

  return (
    <React.Fragment>
      <header id="page-topbar">
        <Container fluid>
          <Row>
            <Col sm={6} className="d-flex align-items-center ps-4">
              <h1 className="mb-0">{env?.company_title}</h1>
            </Col>
            <Col sm={2} className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={() => {
                  dispatch(toggleLeftmenu(!leftMenu));
                }}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </Col>
            <Col sm={2} className="d-flex justify-content-end pe-0">
              {/* <NotificationDropdown /> */}
            </Col>
            <Col sm={2} className="ps-0">
              <ProfileMenu />
            </Col>
          </Row>
        </Container>
      </header>

    </React.Fragment >
  );
};

export default Header;

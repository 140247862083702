import { Button, Card, CardBody, CardFooter, Col, Form, Input, Label, Row } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { errorToast, successToast } from '../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadVesselLubeOils } from 'VesselMaster/vesselMaster.hooks';
import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';

const LubeOil = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    const { data: vessellubeoilObject, isLoading: vessellubeoilObjectLoading, isError: vessellubeoilObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselLubeOils.key],
            async () => { return await loadVesselLubeOils(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    const getInitialValueVesselLubeOilForm = () => {
        if (vessellubeoilObject?.length > 0 && vessellubeoilObject[0].id > 0) {
            return vessellubeoilObject;
        } else {
            return [];
        }
    }

    const lubeoilFormik = {
        initialValues: {
            vessellubeoil: getInitialValueVesselLubeOilForm(),
        },
    };

    return (
        <>
            <Card className='border-0'>
                {vessellubeoilObjectLoading && <Loading message='Loading required data!' />}
                {vessellubeoilObjectError && <ErrorComponent message='Unable to load required data!' />}
                {!(vessellubeoilObjectLoading) && !(vessellubeoilObjectError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            const handleResponse = (response: any) => {
                                if (response.status === 200) {
                                    successToast("Data saved successfully!");
                                }
                            };

                            values.vessellubeoil.forEach((oil: any) => {
                                apiGlobal.put(`/vessel_lube_oil_list/${oil.id}/`, oil)
                                    .then(res => handleResponse(res))
                                    .catch(err => {
                                        if (err.response && errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occurred, please contact the admin");
                                        }
                                    });
                            });
                        }}
                        initialValues={lubeoilFormik.initialValues}
                    >
                        {(props: any) => (
                            <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0 '>
                                    <Row>
                                        <Col sm={{ size: 6, offset: 3 }} className="px-0">
                                            <div className="table-responsive mb-5">
                                                <table className="table">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className='p-2 text-center sr-no-width'>#</th>
                                                            <th className='p-2'>Lube oil</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='vessellubeoil'>
                                                            {() => (
                                                                <>
                                                                    {props.values?.vessellubeoil?.map((vessellubeoil: any, index: number) => (
                                                                        <tr key={vessellubeoil.id}>
                                                                            <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                            <td className='p-2 align-middle'>
                                                                                <div className='ele_row1'>
                                                                                    <div className="form-check">
                                                                                        <Field name={`vessellubeoil.${index}.status`} >
                                                                                            {({ field, form }: FieldProps) => (
                                                                                                <Input
                                                                                                    className="form-check-input mr-1"
                                                                                                    type="checkbox"
                                                                                                    id={`status-${index}`}
                                                                                                    name={`vessellubeoil.${index}.status`}
                                                                                                    checked={vessellubeoil.status}
                                                                                                    onChange={(e: any) => {
                                                                                                        props.setFieldValue(`vessellubeoil[${index}].status`, e.target.checked);
                                                                                                    }}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </div>
                                                                                    <Label for={`status-${index}`} className="mb-0">
                                                                                        {vessellubeoil.lube_name}</Label>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className='p-2 py-3 mb-3'>
                                    <Row>
                                        <Col className='pb-3'>
                                            <Button type="submit" color='primary' className='pos-end'>Save</Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props.values, props.errors, lubeoilFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card >
        </>
    );
}

export default LubeOil;


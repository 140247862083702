import { useFormikContext } from 'formik'
import { formatBytes } from 'GenericForms/Helper'
import {  MediaBaseURL } from 'global/api.global'
import Dropzone from 'react-dropzone'
import { Button, CardBody, Row, } from 'reactstrap'
import { FileStatus } from 'shared/constants'

interface SpOpLogBookUploadProps {
    logBook: any;
    setLogBook: React.Dispatch<React.SetStateAction<any[]>>;
}

const SpOpLogBookUpload: React.FC<SpOpLogBookUploadProps> = ({ logBook, setLogBook }) => {
    const { values }: { values: any, errors: any, touched: any } = useFormikContext();
    const checkUploaded = values?.logBook?.status === FileStatus.UPLOADED;

    /** Handle Uploaded files and save them to useState variable*/
    const handleAcceptedFiles = async (logFiles: any[]) => {
        const updatedFiles = [{
            file: logFiles[0],
            preview: URL.createObjectURL(logFiles[0]),
            formattedSize: formatBytes(logFiles[0].size),
        }]
        setLogBook(updatedFiles);
    }
    return (
        <CardBody className='p-0'>
            <Row>
                <div className='d-flex flex-row'>
                    <Dropzone
                        onDrop={logFile => {
                            handleAcceptedFiles(logFile);
                        }}
                    >
                        {({ getRootProps, getInputProps,

                        }) => (
                            <div
                                className="needsclick"
                                {...getRootProps()}
                            >
                                <input
                                    {...getInputProps()}
                                    name={'logFiles.file_name'}
                                />
                                <Button type="button" className="btn waves-effect btn-label waves-light mr-1"
                                    color='primary'
                                    title={checkUploaded ? 'Replace Existing Log Book File' : 'Please upload log book file'}
                                >
                                    <i className="mdi mdi-upload label-icon" />
                                    <i className="mdi mdi-upload label-icon" />
                                    {checkUploaded ?
                                        'Replace File' :
                                        'Log Book Upload'}
                                </Button>
                            </div>
                        )}
                    </Dropzone>
                    {checkUploaded &&
                        <a className='' href={`${MediaBaseURL}/${values.logBook.file_name.substring(values.logBook.file_name.lastIndexOf('/') + 1)}`}
                            target="_blank" rel="noopener noreferrer" title='View Uploaded File'
                        >
                            <Button type="button" className="btn waves-effect btn-label waves-light mr-1"
                                color='primary'>
                                <i className="mdi mdi-file-document-outline label-icon" />
                                View File
                            </Button>
                        </a>
                    }
                </div>
            </Row>
        </CardBody>
    )
}

export default SpOpLogBookUpload
import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { Row } from "reactstrap";

class ComplianceBalanceGraph extends Component<any> {
    Chart = () => {
        const totalSteps = 12;
        const minY = -250000;
        const maxY = 0;

        // Generate the series data: y-values starting from 0 and decrementing evenly down to -250000
        const yData = Array.from({ length: totalSteps }, (_, i) => maxY - i * (maxY - minY) / (totalSteps - 1));

        return {
            xAxis: {
                type: 'category',
                data: ['Jan-25', 'Feb-25', 'Mar-25', 'Apr-25', 'May-25', 'Jun-25', 'Jul-25', 'Aug-25', 'Sep-25', 'Oct-25', 'Nov-25', 'Dec-25'],
                axisLabel: {
                    interval: 0, // Show all labels
                    rotate: 45,  // Rotate labels if necessary
                },
            },
            yAxis: {
                type: 'value',
                min: minY,
                max: maxY,
                interval: 50000, // Set interval for gridlines
                axisLabel: {
                    formatter: '{value}',
                },
            },
            series: [
                {
                    data: yData, // Diagonal line from 0 to -250000
                    type: 'line',
                    symbol: 'none',  // No point markers
                    lineStyle: {
                        width: 2,
                    },
                },
            ],
            grid: {
                bottom: 80,  // Adjust grid for rotated labels
            },
        };
    };

    render() {
        return (
            <>
                <Row>
                    <ReactEcharts style={{ height: "350px" }} option={this.Chart()} />
                </Row>
            </>
        );
    }
}

export default ComplianceBalanceGraph;

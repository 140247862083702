import React from 'react'
import VesselDetailsHeader from 'Components/VesselDetailsHeader'
import Layout from 'HorizontalMenu/Menu'
import { RootState } from 'index'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Navbar, Container, Row, Col, Label, Card, CardBody, Badge } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import { queryKeyes } from 'shared/queryKeys'
import { setVesselID } from 'Store/Generic/ReportingSlice'
import { loadFuelEUAnalysisCumulativeData, loadFuelEUAnalysisData, loadLastUpdatedOn } from 'VesselMaster/vesselMaster.hooks'
import GHGIEGraph from './GHGIEGraph'
import ComplianceBalanceGraph from './ComplianceBalanceGraph'
import FuelEnergyDeveloped from './FuelEnergyDeveloped'
import VoyagewiseCompliance from './VoyagewiseCompliance'
import { userVessels } from 'utils/auth/authUtils'
import Loading from 'Components/Loading'
import ErrorComponent from 'Components/ErrorComponent'
import { GHGIETarget } from 'shared/constants'
import EUMRVFuelEUTable from 'Components/EUMRVFuelEUTable'

const FuelEUAnalysis = () => {
    /** State variables start */
    const dispatch = useDispatch();
    const { Vessels, VesselID } = useSelector(
        (state: RootState) => state.Reporting
    );
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    let applicableVessels = userVessels();
    /** State variables end */

    /** Queries */
    /** Last report which is sent's date */
    const {
        data: UpdatedDate,
        isLoading: UpdatedDateLoading,
        isError: UpdatedDateError,
    } = useQuery(
        [queryKeyes.vessel.LastUpdatedOn.key, VesselID],
        async () => {
            return await loadLastUpdatedOn(VesselID);
        },
        { staleTime: Infinity }
    );
    /** FuelEU analysis data */
    const {
        data: FuelEUData,
        isLoading: FuelEUDataLoading,
        isError: FuelEUDataError,
    } = useQuery(
        [queryKeyes.vessel.FuelEUAnalysisData.key, VesselID],
        async () => {
            return await loadFuelEUAnalysisData(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Last report which is sent's date */
    const {
        data: FuelEUCumulativeData,
        isLoading: FuelEUCumulativeDataLoading,
        isError: FuelEUCumulativeDataError,
    } = useQuery(
        [queryKeyes.vessel.FuelEUAnalysisCumulativeData.key, VesselID],
        async () => {
            return await loadFuelEUAnalysisCumulativeData(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Function for global vessel selection */
    const handleVesselSelectionChanges = (e: any) => {
        dispatch(setVesselID(e.id));
    };

    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className="mb-3">
                        <Col sm={4}>
                            <Label className="mb-0">Select vessel</Label>
                            <Select
                                options={
                                    Vessels.filter((item: any) => item.display === true &&
                                        applicableVessels?.includes(item?.id)) as any[]
                                }
                                getOptionLabel={(option: any) => option.vessel_name}
                                getOptionValue={(option: any) => option.id}
                                onChange={(e: any) => handleVesselSelectionChanges(e)}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                                defaultValue={{
                                    id: VesselID,
                                    vessel_name: vessel?.vessel_name,
                                }}
                            />
                        </Col>
                        <Col sm={8} className="pt-3 text-end">
                            <div className="d-inline-block">
                                <h3 className="m-0">FuelEU Maritime Assessment</h3>
                            </div>
                        </Col>
                    </Row>
                    {(UpdatedDateLoading || FuelEUDataLoading || FuelEUCumulativeDataLoading) &&
                        <Loading message='Loading required data!' />}
                    {(UpdatedDateError || FuelEUDataError || FuelEUCumulativeDataError) &&
                        <ErrorComponent message='Error loading required data!' />}
                    {!(UpdatedDateLoading || FuelEUDataLoading || FuelEUCumulativeDataLoading) &&
                        !(UpdatedDateError || FuelEUDataError || FuelEUCumulativeDataError) &&
                        <React.Fragment>
                            <Row className='mb-2'>
                                <Col sm={3} className="d-flex align-items-end pr-0">
                                    <p className="mb-0">
                                        Last updated on:
                                        <strong>
                                            {" "}
                                            {UpdatedDate?.date?.toString().substring(0, 10)}
                                        </strong>
                                    </p>
                                </Col>
                                <Col sm={8}>
                                    <VesselDetailsHeader />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='ele_row1 justify-content-center'>
                                    <Card className="bg-success border-success text-white width-14">
                                        <CardBody className='px-2'>
                                            <h5 className="mb-2 text-center px-0">GHGIE Target</h5>
                                            <p className="card-text mb-0 coloured-bg-value text-center">
                                                {GHGIETarget}<br />
                                            </p>
                                            <p className='text-center mb-0'>gCO<sub>2eq</sub>/MJ</p>
                                        </CardBody>
                                    </Card>
                                    <Card className="bg-info border-info text-white width-14 ms-2">
                                        <CardBody className='px-2'>
                                            <h5 className="mb-2 text-center">GHGIE Actual(Live)</h5>
                                            <p className="card-text mb-0 coloured-bg-value text-center">
                                                {FuelEUCumulativeData?.ghg_intensity?.toFixed(3)}<br />
                                            </p>
                                            <p className='text-center mb-0'>gCO<sub>2eq</sub>/MJ</p>
                                        </CardBody>
                                    </Card>
                                    <Card className="bg-secondary border-secondary text-white width-14 ms-2">
                                        <CardBody className='px-2'>
                                            <h5 className="mb-2 text-center">Deviation</h5>
                                            <p className="card-text mb-0 coloured-bg-value text-center">
                                                {`${(parseFloat(FuelEUCumulativeData?.ghg_intensity?.toFixed(3)) / GHGIETarget) >= 1 ?
                                                    '-' : ''}
                                                ${Number.isNaN(parseFloat((parseFloat(FuelEUCumulativeData?.ghg_intensity?.toFixed(3)) / GHGIETarget)?.toFixed(2))) === true ?
                                                        '0.00' :
                                                        (parseFloat(FuelEUCumulativeData?.ghg_intensity?.toFixed(3)) / GHGIETarget)?.toFixed(2)}`}
                                                < br />
                                            </p>
                                            <p className='text-center mb-0'>%</p>
                                        </CardBody>
                                    </Card>
                                    <Card className="bg-warning border-warning text-white width-14 ms-2">
                                        <CardBody className='px-2'>
                                            <h5 className="mb-2 text-center">Comp. Balance (YTD)</h5>
                                            <p className="card-text mb-0 coloured-bg-value text-center">
                                                -12,923<br />
                                            </p>
                                            <div className='text-center'>
                                                <Badge className="bg-danger">Deficit</Badge>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <Card className="bg-danger border-danger text-white width-14 ms-2">
                                        <CardBody className='px-2'>
                                            <h5 className="mb-2 text-center">Penalty (YTD)</h5>
                                            <p className="card-text mb-0 coloured-bg-value text-center">
                                                1,223<br />
                                            </p>
                                            <p className='text-center mb-0'>EUR</p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className='mb-3'>
                                <Col sm={4}>
                                    <h6 className='mb-0'>Live GIGHE Graph</h6>
                                    <GHGIEGraph fuelEUData={FuelEUData} />
                                </Col>
                                <Col sm={4}>
                                    <h6 className='mb-0'>Live Compliance Balance Graph</h6>
                                    <ComplianceBalanceGraph />
                                </Col>
                                <Col sm={2}>
                                    <h6 className='mb-0'>Fuelwise contribution to energy developed</h6>
                                    <FuelEnergyDeveloped fuelEUData={FuelEUCumulativeData} />
                                </Col>
                                <Col sm={2}>
                                    <h6 className='mb-0'>Voyagewise compliance</h6>
                                    <VoyagewiseCompliance />
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                    <Row>
                        <EUMRVFuelEUTable eumrvData={[]} operation='FuelEU' />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default FuelEUAnalysis
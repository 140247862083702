import { Button, Row, Col, Card, Input, CardBody, CardFooter, Form } from "reactstrap";
import apiGlobal from '../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../';
import { fetchMachinaryOptions } from '../../VesselMaster/vesselMaster.hooks';
import { errorToast, successToast } from '../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { Formik, FieldProps, Field, FieldArray } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import * as Yup from "yup";
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import env from 'environment/env';
import ErrorTooltip from 'Components/ErrorTooltip';



const SFOC = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    /** Queries */
    /** Machinery on vessel */
    const { data: SFOCObject, isLoading: SFOCObjectLoading, isError: SFOCObjectError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.Machinary.key, VesselID],
            async () => { return await fetchMachinaryOptions(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    const getInitialValueSFOCForm = () => {
        if (SFOCObject?.length > 0 && SFOCObject[0].id > 0) {
            return SFOCObject;
        } else {
            return []
        }
    }

    const sfocFormik = {
        initialValues: { sfoc: getInitialValueSFOCForm() },
        validationSchema: Yup.object().shape({
            sfoc: Yup.array().of(
                Yup.object().shape({
                    sfoc_required: Yup.boolean(),
                    eedi_eexi_technical_file: Yup.string().when('sfoc_required', {
                        is: true,
                        then: (schema) => schema.required(commonValidationMessages.required),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                    nox_technical_file: Yup.string().when('sfoc_required', {
                        is: true,
                        then: (schema) => schema.required(commonValidationMessages.required),
                        otherwise: (schema) => schema.notRequired(),
                    }),
                })
            )
        })
    };

    return (
        <>
            <Card className='border-0'>
                {(SFOCObjectLoading) && <Loading message='Loading required data!' />}
                {(SFOCObjectError) && <ErrorComponent message='Unable to load required data!' />}
                {SFOCObjectError && getInitialValueSFOCForm()}
                {!(SFOCObjectLoading) &&
                    !(SFOCObjectError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            const handleResponse = (response: any) => {
                                if (response.status === 200) {
                                    successToast("Data saved successfully!");
                                }
                            }

                            if (SFOCObject?.length > 0 && SFOCObject[0].id > 0) {
                                values.sfoc.forEach((machine: any) => {
                                    apiGlobal.put(`/vessel_machinery_list/${machine.id}/`, machine).then(res => {
                                        handleResponse(res);
                                    }).catch(err => {
                                        if (errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occured, please contact the admin")
                                        }
                                    })
                                })
                            }
                        }
                        }
                        initialValues={sfocFormik.initialValues}
                        validationSchema={sfocFormik.validationSchema}
                    >
                        {(props: any) => (
                            <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0'>
                                    <Row>
                                        <Col sm={12} className="px-0">
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                            <th className='p-2 align-middle'>Machinery Name</th>
                                                            <th className='p-2 text-center align-middle' style={{width:'10%'}}>SFOC</th>
                                                            <th className='p-2 text-center align-middle' style={{width:'20%'}}>EEDI/EEXI technical file</th>
                                                            <th className='p-2 text-center align-middle' style={{width:'20%'}}>NOx technical file</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='sfoc'>
                                                            {() => (
                                                                <>
                                                                    {props?.values &&
                                                                        props?.values?.sfoc?.map((sfoc: any, index: number) => {
                                                                            return (
                                                                                <tr key={sfoc?.id}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{sfoc?.vessel_machinery_name}</td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <div className="d-inline-block">
                                                                                            <Field name={`sfoc.${index}.sfoc_required`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type='checkbox'
                                                                                                        id={`sfoc_required-${index}`}
                                                                                                        name={field.name}
                                                                                                        // checked={field.value}
                                                                                                        onBlur={props.handleBlur}
                                                                                                        onChange={(e: any) => {
                                                                                                            props?.handleChange(e);
                                                                                                        }}
                                                                                                        defaultChecked={props?.values?.sfoc[index]?.sfoc_required}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <div className="d-inline-block">
                                                                                            <Field name={`sfoc.${index}.eedi_eexi_technical_file`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type='text'
                                                                                                        id={`eedi_eexi_technical_file-${index}`}
                                                                                                        name={field.name}
                                                                                                        value={field.value}
                                                                                                        disabled={!props?.values?.sfoc[index]?.sfoc_required}
                                                                                                        onBlur={props.handleBlur}
                                                                                                        onChange={props.handleChange}
                                                                                                        className="max-width-7 text-right"
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                        {props.errors.sfoc && props.touched.sfoc &&
                                                                                            props.touched.sfoc[index]?.eedi_eexi_technical_file &&
                                                                                            props.errors.sfoc[index]?.eedi_eexi_technical_file && env?.form_validation === true && (
                                                                                                <ErrorTooltip
                                                                                                    target={`eedi_eexi_technical_file-${index}`}
                                                                                                    message={props.errors.sfoc[index]?.eedi_eexi_technical_file}
                                                                                                    open={!!props.errors.sfoc[index]?.eedi_eexi_technical_file}
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <div className="d-inline-block">
                                                                                            <Field name={`sfoc.${index}.nox_technical_file`}>
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <Input
                                                                                                        type='text'
                                                                                                        id={`nox_technical_file-${index}`}
                                                                                                        name={field.name}
                                                                                                        // value={field.value}
                                                                                                        disabled={!props?.values?.sfoc[index]?.sfoc_required}
                                                                                                        onChange={(e: any) => {
                                                                                                            props?.handleChange(e);
                                                                                                        }}
                                                                                                        className="max-width-7 text-right"
                                                                                                        defaultValue={props.values?.sfoc[index]?.nox_technical_file}
                                                                                                    />
                                                                                                )}
                                                                                            </Field>
                                                                                        </div>
                                                                                        {props.errors.sfoc && props.touched.sfoc &&
                                                                                            props.touched.sfoc[index]?.nox_technical_file &&
                                                                                            props.errors.sfoc[index]?.nox_technical_file && env?.form_validation === true && (
                                                                                                <ErrorTooltip
                                                                                                    target={`nox_technical_file-${index}`}
                                                                                                    message={props.errors.sfoc[index]?.nox_technical_file}
                                                                                                    open={!!props.errors.sfoc[index]?.nox_technical_file}
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )}
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className='p-2 py-3 mb-3'>
                                    <Row>
                                        <Col className='pb-3'>
                                            <Button type="submit" color='primary' className='pos-end'>Save</Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props?.values, props?.errors, sfocFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card >
        </>
    );
};
export default SFOC;
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
    Col,
    Row,
    Input,
    Label,
    Button,
    Form,
    Modal,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
} from "reactstrap";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { getInputs } from "../EngineerReport.model";
import {
    loadEnergyParameterObject,
    loadVesselMachineries,
} from "VesselMaster/vesselMaster.hooks";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import { errorToast, successToast } from "Components/Toasts";
import { TooltipMsg } from "Components/ToolTipMessage";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import apiGlobal from "global/api.global";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import { errResponse } from "GenericForms/Helper";
import env from "environment/env";
import ErrorTooltip from "Components/ErrorTooltip";
import ToolTip from "Components/ToolTip";

interface EnergyParameterType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    activeTab: number;
    toggleTab: any;
}

const EnergyParameterCompenent = ({
    ReportID,
    VesselID,
    VoyageID,
    activeTab,
    toggleTab,
}: EnergyParameterType) => {
    /** State variables */
    let { Energy_inputs } = getInputs("energy");
    const [modal_backdrop, setmodal_backdrop] = useState(false);
    const [modalIndex, setModalIndex] = useState(0);
    /** State variables end */

    /** Queries */
    /** Vessel Machineries */
    const {
        data: vesselMachineries,
        isLoading: vesselMachineriesLoading,
        isError: vesselMachineriesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.Machinary.key, VesselID],
        async () => {
            return await loadVesselMachineries(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Energy parameter object used for edit */
    const {
        data: EnergyParameterObject,
        isLoading: EnergyParameterObjectLoading,
        isError: EnergyParameterObjectError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.EnergyParameterObject.key, VesselID, ReportID],
        async () => {
            return await loadEnergyParameterObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to initial object of Energy Parameter */
    const getInitialEneryParameterValues = () => {
        let obj: any[] = [];
        if (EnergyParameterObject?.length > 0 && EnergyParameterObject[0].id > 0) {
            return EnergyParameterObject;
        } else if (
            vesselMachineries !== undefined &&
            obj.length <=
            vesselMachineries.filter((item: any) => item.energy_parameter === true)
                .length
        ) {
            vesselMachineries
                .filter((item: any) => item.energy_parameter === true)
                .map((vesselMachinery: any, indx: number) => {
                    obj.push({
                        energy_developed: null,
                        running_hours: null,
                        avg_power: null,
                        is_energy_meter_functional: false,
                        machinery_name: vesselMachinery.id,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                    });
                    return vesselMachinery;
                });
            return obj;
        } else {
            return [
                {
                    energy_developed: 0,
                    running_hours: 0,
                    avg_power: 0,
                    is_energy_meter_functional: false,
                    machinery_name: 0,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                },
            ];
        }
    };

    /**useEffect start */
    /** Create and assign initial object to Formik's initial values' object  */
    useEffect(() => {
        EnergyParameterFormik.initialValues.energyParameter =
            getInitialEneryParameterValues();
    }, [vesselMachineries, EnergyParameterObject]);
    /**useEffect end */

    /** Energy Parameter's formik object */
    const EnergyParameterFormik = {
        initialValues: {
            energyParameter: getInitialEneryParameterValues(),
        },
        validationSchema: Yup.object().shape({
            energyParameter: Yup.array(
                Yup.object({
                    energy_developed: Yup.string()
                        .min(1, `${commonValidationMessages.min1}`)
                        .max(12, `${commonValidationMessages.max12}`)
                        .when("is_energy_meter_functional", {
                            is: false,
                            then: (schema: any) =>
                                schema.required(commonValidationMessages.required),
                            otherwise: (schema: any) => schema.nullable(),
                        }),
                    running_hours: Yup.string()
                        .matches(
                            /^[0-9]{0,2}(\.[0-9]{1,2})?$/,
                            `${commonValidationMessages.before2after2}`
                        )
                        .when("is_energy_meter_functional", {
                            is: false,
                            then: (schema: any) =>
                                schema.required(commonValidationMessages.required),
                            otherwise: (schema: any) => schema.nullable(),
                        }),
                    avg_power: Yup.string()
                        .matches(
                            /^\d{0,5}(?:\.\d{1,2})?$/,
                            `${commonValidationMessages.before5after2}`
                        )
                        .when("is_energy_meter_functional", {
                            is: true,
                            then: (schema: any) =>
                                schema.required(commonValidationMessages.required),
                            otherwise: (schema: any) => schema.nullable(),
                        }),
                })
            ),
        }),
    };

    /** Open-close modal */
    function tog_backdrop() {
        setmodal_backdrop(!modal_backdrop);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Handle changes of energy developed, avg power & running hours */
    const handleEnergyParamterTextBoxChanges = (
        e: any,
        form: any,
        values: any,
        name: any,
        indx: number
    ) => {
        if (
            name === `energyParameter.${indx}.energy_developed` &&
            values?.energyParameter[indx]
        ) {
            if (parseFloat(e.target.value) > 0) {
                form.setFieldValue(`energyParameter.${indx}.avg_power`,
                    (parseFloat(e.target.value) / values?.energyParameter[indx].running_hours).toFixed(2)
                );
            } else {
                form.setFieldValue(`energyParameter.${indx}.avg_power`, 0.00);
            }
        } else if (
            name === `energyParameter.${indx}.avg_power` &&
            values?.energyParameter[indx]
        ) {
            form.setFieldValue(
                `energyParameter.${indx}.energy_developed`,
                (parseFloat(e.target.value) * values?.energyParameter[indx].running_hours).toFixed(2)
            );
        } else if (
            name === `energyParameter.${indx}.running_hours` &&
            values?.energyParameter[indx].is_energy_meter_functional === false
        ) {
            if (parseFloat(e.target.value) > 0) {
                form.setFieldValue(
                    `energyParameter.${indx}.avg_power`,
                    (values?.energyParameter[indx].energy_developed / parseFloat(e.target.value)).toFixed(2)
                );
            } else {
                form.setFieldValue(`energyParameter.${indx}.avg_power`, 0.00)
            }
        } else if (
            name === `energyParameter.${indx}.running_hours` &&
            values?.energyParameter[indx].is_energy_meter_functional === true
        ) {
            form.setFieldValue(
                `energyParameter.${indx}.energy_developed`,
                (values?.energyParameter[indx].avg_power * parseFloat(e.target.value)).toFixed(2)
            );
        }
    };

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2 border-bottom-0">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Energy Parameters</h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(vesselMachineriesLoading || EnergyParameterObjectLoading) && (
                <Loading message="Loading required data!" />
            )}
            {vesselMachineriesError && (
                <ErrorComponent message="Unable to load required data!" />
            )}
            {EnergyParameterObjectError && getInitialEneryParameterValues()}
            {!vesselMachineriesLoading &&
                !vesselMachineriesError &&
                !EnergyParameterObjectLoading && (
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            const handleResponse = (response: any) => {
                                if (response.status === 201 || response.status === 200) {
                                    successToast("Data saved successfully!");
                                    queryClient.invalidateQueries(
                                        queryKeyes.vessel.EnergyParameterObject.key
                                    );
                                    if (env?.form_validation === true) {
                                        toggleTab(activeTab + 1);
                                    }
                                }
                            };
                            /** Energy parameter submit */
                            if (
                                EnergyParameterObject?.length > 0 &&
                                EnergyParameterObject[0].id > 0
                            ) {
                                values.energyParameter.map((param: any) => {
                                    apiGlobal
                                        .put(`/energy_parameter/${param.id}/`, param)
                                        .then((res) => {
                                            handleResponse(res);
                                        })
                                        .catch((err) => {
                                            if (errResponse.includes(err.response.status)) {
                                                errorToast(
                                                    "Internal error occured, please contact the admin"
                                                );
                                            }
                                        });
                                    return "";
                                });
                            } else {
                                apiGlobal
                                    .post(`/energy_parameter/`, values.energyParameter)
                                    .then((res) => {
                                        handleResponse(res);
                                    })
                                    .catch((err) => {
                                        if (errResponse.includes(err.response.status)) {
                                            errorToast(
                                                "Internal error occured, please contact the admin"
                                            );
                                        }
                                    });
                            }
                        }}
                        initialValues={EnergyParameterFormik.initialValues}
                        validationSchema={EnergyParameterFormik.validationSchema}
                    >
                        {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            setErrors,
                            touched,
                            handleBlur,
                        }: {
                            values: any;
                            errors: any;
                            handleSubmit: any;
                            handleChange: any;
                            setErrors: any;
                            touched: any;
                            handleBlur: any;
                        }) => (
                            <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                                <CardBody className="engineer-card-body">
                                    <div className="table-responsive p-0">
                                        <table className="table mb-2">
                                            <thead className="table-light">
                                                <tr>
                                                    <th className="p-2 align-middle sr-no-width">#</th>
                                                    {Energy_inputs.filter(
                                                        (table: any) => table.table_columns === true
                                                    )
                                                        .sort(
                                                            (a: any, b: any) =>
                                                                a.table_columns_sequence -
                                                                b.table_columns_sequence
                                                        )
                                                        .map(({ name, ...props }) => {
                                                            return (
                                                                <th
                                                                    key={props.id}
                                                                    className={`p-2 align-middle ${name === "machinery_name"
                                                                        ? "text-left"
                                                                        : "text-center"
                                                                        } ${props?.className}`}
                                                                >
                                                                    {props.label}
                                                                </th>
                                                            );
                                                        })}
                                                    <th
                                                        className="p-2 align-middle text-center"
                                                        style={{ width: "17%" }}
                                                    >
                                                        Sensor status
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <FieldArray name="energyParameter">
                                                    {() => (
                                                        <>
                                                            {vesselMachineries &&
                                                                vesselMachineries
                                                                    .filter(
                                                                        (item: any) =>
                                                                            item.energy_parameter === true
                                                                    )
                                                                    .map((vesselMachinery: any, indx: number) => {
                                                                        return (
                                                                            <>
                                                                                <tr key={vesselMachinery.id}>
                                                                                    <td className="p-2 align-middle text-center">
                                                                                        {indx + 1}
                                                                                    </td>
                                                                                    <td className="p-2 align-middle">
                                                                                        {
                                                                                            vesselMachinery.vessel_machinery_name
                                                                                        }
                                                                                    </td>
                                                                                    <td className="p-2 align-middle text-center">
                                                                                        <div className="d-inline-block">
                                                                                            <div className="input-group">
                                                                                                <Field
                                                                                                    name={`energyParameter.${indx}.energy_developed`}
                                                                                                >
                                                                                                    {({
                                                                                                        field,
                                                                                                        form,
                                                                                                    }: FieldProps) => (
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            className="form-control text-right max-width-7"
                                                                                                            id={`energy_developed_${indx}`}
                                                                                                            name={field.name}
                                                                                                            value={
                                                                                                                values?.energyParameter[
                                                                                                                    indx
                                                                                                                ]
                                                                                                                    ?.is_energy_meter_functional ===
                                                                                                                    true
                                                                                                                    ? values
                                                                                                                        ?.energyParameter[
                                                                                                                        indx
                                                                                                                    ]?.energy_developed
                                                                                                                    : null
                                                                                                            }
                                                                                                            defaultValue={
                                                                                                                EnergyParameterObject?.length >
                                                                                                                    0 &&
                                                                                                                    EnergyParameterObject[0]
                                                                                                                        .id > 0
                                                                                                                    ? values
                                                                                                                        ?.energyParameter[
                                                                                                                        indx
                                                                                                                    ]?.energy_developed
                                                                                                                    : null
                                                                                                            }
                                                                                                            onBlur={(e: any) => {
                                                                                                                handleBlur(e);
                                                                                                                handleChange(e);
                                                                                                                handleEnergyParamterTextBoxChanges(
                                                                                                                    e,
                                                                                                                    form,
                                                                                                                    values,
                                                                                                                    field.name,
                                                                                                                    indx
                                                                                                                );
                                                                                                            }}
                                                                                                            disabled={
                                                                                                                values?.energyParameter[
                                                                                                                    indx
                                                                                                                ]
                                                                                                                    ?.is_energy_meter_functional
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <div className="input-group-text">
                                                                                                    kWh
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {errors?.energyParameter &&
                                                                                            touched?.energyParameter &&
                                                                                            touched?.energyParameter[indx]
                                                                                                ?.energy_developed &&
                                                                                            errors?.energyParameter[indx]
                                                                                                ?.energy_developed &&
                                                                                            env?.form_validation === true && (
                                                                                                <ErrorTooltip
                                                                                                    target={`energy_developed_${indx}`}
                                                                                                    message={
                                                                                                        errors?.energyParameter[
                                                                                                            indx
                                                                                                        ]?.energy_developed
                                                                                                    }
                                                                                                    open={
                                                                                                        errors?.energyParameter &&
                                                                                                            errors?.energyParameter[
                                                                                                                indx
                                                                                                            ]?.energy_developed
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                    <td className="p-2 align-middle text-center">
                                                                                        <div className="d-inline-block">
                                                                                            <div className="input-group">
                                                                                                <Field
                                                                                                    name={`energyParameter.${indx}.running_hours`}
                                                                                                >
                                                                                                    {({
                                                                                                        field,
                                                                                                        form,
                                                                                                    }: FieldProps) => (
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            className="form-control text-right max-width-7"
                                                                                                            id={`running_hours_${indx}`}
                                                                                                            name={field.name}
                                                                                                            onBlur={(e: any) => {
                                                                                                                handleBlur(e);
                                                                                                                handleChange(e);
                                                                                                                handleEnergyParamterTextBoxChanges(
                                                                                                                    e,
                                                                                                                    form,
                                                                                                                    values,
                                                                                                                    field.name,
                                                                                                                    indx
                                                                                                                );
                                                                                                            }}
                                                                                                            defaultValue={
                                                                                                                values?.energyParameter[
                                                                                                                    indx
                                                                                                                ]?.running_hours
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <div className="input-group-text">
                                                                                                    hr
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {errors?.energyParameter &&
                                                                                            touched?.energyParameter &&
                                                                                            touched?.energyParameter[indx]
                                                                                                ?.running_hours &&
                                                                                            errors?.energyParameter[indx]
                                                                                                ?.running_hours &&
                                                                                            env?.form_validation === true && (
                                                                                                <ErrorTooltip
                                                                                                    target={`running_hours_${indx}`}
                                                                                                    message={
                                                                                                        errors?.energyParameter[
                                                                                                            indx
                                                                                                        ]?.running_hours
                                                                                                    }
                                                                                                    open={
                                                                                                        errors?.energyParameter &&
                                                                                                            errors?.energyParameter[
                                                                                                                indx
                                                                                                            ]?.running_hours
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                    <td className="p-2 align-middle text-center">
                                                                                        <div className="d-inline-block">
                                                                                            <div className="input-group">
                                                                                                <Field
                                                                                                    name={`energyParameter.${indx}.avg_power`}
                                                                                                >
                                                                                                    {({
                                                                                                        field,
                                                                                                        form,
                                                                                                    }: FieldProps) => (
                                                                                                        <Input
                                                                                                            type="text"
                                                                                                            className="form-control text-right max-width-7"
                                                                                                            id={`avg_power-${indx}`}
                                                                                                            name={field.name}
                                                                                                            value={
                                                                                                                values?.energyParameter[indx]?.is_energy_meter_functional === false ?
                                                                                                                    Number.isNaN(parseFloat(values?.energyParameter[indx]?.avg_power)) ||
                                                                                                                        parseFloat(values?.energyParameter[indx]?.avg_power) === Number.POSITIVE_INFINITY ||
                                                                                                                        parseFloat(values?.energyParameter[indx]?.avg_power) === Number.NEGATIVE_INFINITY
                                                                                                                        ? 0
                                                                                                                        : values?.energyParameter[indx]?.avg_power
                                                                                                                    : null
                                                                                                            }
                                                                                                            defaultValue={
                                                                                                                values?.energyParameter[indx]?.avg_power
                                                                                                            }
                                                                                                            disabled={
                                                                                                                !values?.energyParameter[indx]?.is_energy_meter_functional
                                                                                                            }
                                                                                                            onBlur={(e: any) => {
                                                                                                                handleBlur(e);
                                                                                                                handleChange(e);
                                                                                                                handleEnergyParamterTextBoxChanges(
                                                                                                                    e,
                                                                                                                    form,
                                                                                                                    values,
                                                                                                                    field.name,
                                                                                                                    indx
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                </Field>
                                                                                                <div className="input-group-text">
                                                                                                    kW
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            (errors?.energyParameter &&
                                                                                                errors?.energyParameter[indx]
                                                                                                    ?.avg_power &&
                                                                                                env?.form_validation === true) && (
                                                                                                <ErrorTooltip
                                                                                                    target={`avg_power-${indx}`}
                                                                                                    message={
                                                                                                        errors?.energyParameter[
                                                                                                            indx
                                                                                                        ]?.avg_power
                                                                                                    }
                                                                                                    open={
                                                                                                        errors?.energyParameter &&
                                                                                                            errors?.energyParameter[
                                                                                                                indx
                                                                                                            ]?.avg_power
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            )}
                                                                                    </td>
                                                                                    <td className="p-2 align-middle text-center">
                                                                                        <Button
                                                                                            id={`sensor_${indx}`}
                                                                                            color={
                                                                                                values?.energyParameter[indx]?.is_energy_meter_functional ===
                                                                                                true && "danger"
                                                                                            }
                                                                                            className={`btn ${values?.energyParameter[indx]?.is_energy_meter_functional ===
                                                                                                true
                                                                                                ? "btn-danger"
                                                                                                : "functional-status-color"
                                                                                                } btn_size_8_cstm`}
                                                                                            onClick={() => {
                                                                                                tog_backdrop();
                                                                                                setModalIndex(indx);
                                                                                            }}
                                                                                            onMouseOver={() =>
                                                                                                setModalIndex(indx)
                                                                                            }
                                                                                        >
                                                                                            {values?.energyParameter[indx]?.is_energy_meter_functional ===
                                                                                                false
                                                                                                ? "Functional"
                                                                                                : "Non-functional"}
                                                                                        </Button>
                                                                                        <ToolTip
                                                                                            target={`sensor_${indx}`}
                                                                                            message="Click here to change the status"
                                                                                        />
                                                                                        <Modal
                                                                                            style={{ overflowY: "auto" }}
                                                                                            isOpen={modal_backdrop}
                                                                                            toggle={() => {
                                                                                                tog_backdrop();
                                                                                            }}
                                                                                            backdrop={"static"}
                                                                                            id="staticBackdrop"
                                                                                        >
                                                                                            <div className="modal-header">
                                                                                                <h5
                                                                                                    className="modal-title"
                                                                                                    id="staticBackdropLabel"
                                                                                                >
                                                                                                    Action
                                                                                                </h5>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn-close"
                                                                                                    onClick={() => {
                                                                                                        setmodal_backdrop(false);
                                                                                                    }}
                                                                                                    aria-label="Close"
                                                                                                ></button>
                                                                                            </div>
                                                                                            <div className="modal-body">
                                                                                                <strong>Please Note -</strong>
                                                                                                <p>
                                                                                                    {TooltipMsg.Energy_Parameter.filter(
                                                                                                        (item: any) =>
                                                                                                            item.target ===
                                                                                                            "is_energy_meter_functional_msg"
                                                                                                    ).map((tool: any) => {
                                                                                                        return tool.message;
                                                                                                    })}
                                                                                                </p>
                                                                                                <div className="d-flex flex-wrap gap-2 align-items-center">
                                                                                                    <Label htmlFor="is_energy_meter_functional">
                                                                                                        Energy meter faliure?
                                                                                                    </Label>
                                                                                                    <Field
                                                                                                        name={`energyParameter.${modalIndex}.is_energy_meter_functional`}
                                                                                                    >
                                                                                                        {({
                                                                                                            field,
                                                                                                        }: FieldProps) => (
                                                                                                            <div className="square-switch sqswitch d-flex align-items-center">
                                                                                                                <Input
                                                                                                                    type="checkbox"
                                                                                                                    switch="none"
                                                                                                                    name={field.name}
                                                                                                                    id="is_energy_meter_functional"
                                                                                                                    defaultChecked={
                                                                                                                        values
                                                                                                                            ?.energyParameter[
                                                                                                                            modalIndex
                                                                                                                        ]
                                                                                                                            ?.is_energy_meter_functional
                                                                                                                    }
                                                                                                                    onChange={(
                                                                                                                        e: any
                                                                                                                    ) => {
                                                                                                                        handleChange(e);
                                                                                                                    }}
                                                                                                                />
                                                                                                                <Label
                                                                                                                    htmlFor="is_energy_meter_functional"
                                                                                                                    data-on-label="Yes"
                                                                                                                    data-off-label="No"
                                                                                                                ></Label>
                                                                                                                {errors?.energyParameter &&
                                                                                                                    errors
                                                                                                                        ?.energyParameter[
                                                                                                                        modalIndex
                                                                                                                    ]
                                                                                                                        ?.is_energy_meter_functional &&
                                                                                                                    env?.form_validation ===
                                                                                                                    true && (
                                                                                                                        <ErrorTooltip
                                                                                                                            target={`is_energy_meter_functional`}
                                                                                                                            message={
                                                                                                                                errors
                                                                                                                                    ?.energyParameter[
                                                                                                                                    modalIndex
                                                                                                                                ]
                                                                                                                                    ?.is_energy_meter_functional
                                                                                                                            }
                                                                                                                            open={
                                                                                                                                errors?.energyParameter &&
                                                                                                                                    errors
                                                                                                                                        ?.energyParameter[
                                                                                                                                        modalIndex
                                                                                                                                    ]
                                                                                                                                        ?.is_energy_meter_functional
                                                                                                                                    ? true
                                                                                                                                    : false
                                                                                                                            }
                                                                                                                        />
                                                                                                                    )}
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </Field>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Modal>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        );
                                                                    })}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                                <CardFooter className="p-2 py-3">
                                    <Row className="ele_row1">
                                        <div className="d-flex flex-wrap gap-5">
                                            <Button
                                                type="submit"
                                                color="primary"
                                                className="btn_size_cstm pos-end"
                                                onClick={(e) => {
                                                    if (env?.form_validation === false) {
                                                        toggleTab(activeTab + 1);
                                                    }
                                                }}
                                            >
                                                Next <i className="bx bx-chevron-right ms-1" />
                                            </Button>
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm"
                                                onClick={(e) => {
                                                    setErrors({});
                                                    toggleTab(activeTab - 1);
                                                }}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        </div>
                                    </Row>
                                </CardFooter>
                                <FormValuesDebug
                                    values={[values, errors, EnergyParameterFormik.initialValues]}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
        </Card>
    );
};
export default EnergyParameterCompenent;

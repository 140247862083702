import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { useQuery } from 'react-query';
import { loadCargoHeatingWithkWh } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { Field, FieldProps, Formik, Form, FieldArray } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { queryClient } from 'react-query/queryClient';

const CargoHeating = () => {
    /** Get the VesselID from the Redux store */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /** 
     * Fetches cargo heating machinery data with kWh meter information.
     * Uses the `useQuery` hook to load the cargo heating machinery associated with the vessel.
     */
    const { data: cargoHeatingObject, isLoading: cargoHeatingLoading, isError: cargoHeatingError } = useQuery(
        [queryKeyes.vessel.CargoHeatingWithkWhMachineries.key, VesselID],
        async () => await loadCargoHeatingWithkWh(VesselID),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /** 
     * Initializes the form values based on the cargo heating data.
     * 
     * @returns Cargo heating data to be used as initial values in Formik.
     */
    const getInitialValueSpecialOperationCargoHeatingForm = () => {
        if (cargoHeatingObject?.length > 0 && cargoHeatingObject[0].id > 0) {
            return cargoHeatingObject;
        } else {
            return [];
        }
    };

    /** Formik configuration object for cargo heating form */
    const SpecialOperationCargoHeatingFormik = {
        initialValues: {
            SpecialOperationEc: getInitialValueSpecialOperationCargoHeatingForm(),
        },
        validationSchema: {}, // Define validation schema here if needed
    };
    
    return (
        <Card className="border-0">
            {cargoHeatingLoading && <Loading message="Loading required data!" />}
            {cargoHeatingError && <ErrorComponent message="Unable to load required data!" />}
            {!cargoHeatingLoading && !cargoHeatingError && (
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        values?.SpecialOperationEc?.foreach((machine: any) => {
                            apiGlobal
                                .put(`/special_operation_energy_setting/${machine.id}/`, machine)
                                .then((res) => {
                                    if (res.status === 200) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.CargoHeatingWithkWhMachineries.key);
                                    }
                                })
                                .catch((err) => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occurred, please contact the admin");
                                    }
                                });
                        });
                    }}
                    initialValues={SpecialOperationCargoHeatingFormik.initialValues}
                >
                    {props => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete="off">
                            <CardBody className="p-0">
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive mb-5">
                                            <table className="table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                        <th className='p-2 align-middle'>Machinery name</th>
                                                        <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FieldArray name="SpecialOperationEc">
                                                        {() => (
                                                            SpecialOperationCargoHeatingFormik?.initialValues &&
                                                            SpecialOperationCargoHeatingFormik?.initialValues?.SpecialOperationEc?.map(
                                                                (cargoHeating: any, index: any) => {
                                                                    return(
                                                                    <tr key={index}>
                                                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                        <td className='p-2 align-middle'>{cargoHeating.vessel_machinery_ec_group_name}</td>
                                                                        <td className='p-2 align-middle text-center'>
                                                                            <Field
                                                                                name={`SpecialOperationEc.${index}.cargo_heating_kwh_meter`}
                                                                            >
                                                                                {({ field }: FieldProps) => (
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id={`SpecialOperationEc.${index}.cargo_heating_kwh_meter`}
                                                                                        {...field}
                                                                                    />
                                                                                )}
                                                                            </Field>
                                                                        </td>
                                                                    </tr>
                                                                    )
                                                                }
                                                            )
                                                        )}
                                                    </FieldArray>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="p-2 py-3 mb-3">
                                <Button type="submit" color="primary" className="justify_right">
                                    Save
                                </Button>
                            </CardFooter>
                            <Row className="mt-2">
                                <FormValuesDebug values={[props?.values, props?.errors, SpecialOperationCargoHeatingFormik.initialValues]} />
                            </Row>
                        </Form>
                    )}
                </Formik>
            )}
        </Card>
    );
};

export default CargoHeating;
